export default {
  "useFlickr": "false",
	"galleryTitle": "JØRGEN RASMUSSEN | PHOTOGRAPHY  Gallary: Site Title",
	"textColor": "rgba(255, 255, 255, 1)",
	"thumbFrameColor": "rgba(255, 255, 255, 0.5)",
	"showOpenButton": "true",
	"showExpandButton": "true",
	"useFullscreenExpand": "true",
	"enableDirectLinks": "true",
	"expandInNewPage": "false",
	"topAreaHeight": "0",
	"galleryFontFace": "'Helvetica Neue', Helvetica, Arial, 'lucida grande', tahoma, verdana, arial, sans-serif;",
	"galleryDescription": "Gallery description",
  "galleryTitlePosition": "NONE",
	"showThumbsButton": "true",
	"captionBackColor": "rgba(0,0,0,0)",
	"maxThumbRows": "1",
	"maxThumbColumns": "5",
	"showThumbsOnLoad": "false",
	"showSmallThumbsOnLoad": "false",
	"showSmallThumbsButton": "false",
	"showPagingText": "false",
	"thumbNavPosition": "CENTER",
	"buttonBarHAlign": "RIGHT",
	"showInfoButton": "false",
	"showNavButtons": "false",
	"buttonBarIconSize": "25",
	"captionPosition": "OVERLAY_IMAGE",
	"maxCaptionHeight": "320",
	"showImageNumber": "true",
	"shareFacebook": "true",
	"shareTwitter": "true",
	"shareGPlus": "false",
	"sharePinterest": "true",
	"shareTumblr": "false",
	"shareUrl": "http://www.jorgen-rasmussen.com/portfolio/",
	"addSEOContent": "false",
	"backButtonPosition": "OVERLAY",
	"backButtonUseIcon": "true",
  "backButtonUrl": "http://www.jorgen-rasmussen.com",
  "showSmallBackButton": "true",
	"changeImageOnHover": "false",
	"navButtonIconSize": "20",
	"showSmallPagingText": "false",
	"navButtonBackColor": "rgba(255,255,255,0)",
	"textShadowColor": "rgba(0,0,0,.5)",
	"buttonBarBackColor": "rgba(0,0,0,0)",
	"imageFrameColor": "rgba(255,255,255,0)",
	"buttonBarIconHoverColor": "rgba(204,204,204,1)",
	"buttonBarIconColor": "rgba(255,255,255,.95)",
	"imageScaleMode": "SCALE",
	"buttonBarPosition": "OVERLAY",
	"backButtonHAlign": "LEFT",
	"useLargeImages": "true",
	"useSmallImages": "true",
  "images": [          {
        "id": "CP_JRASMUSSEN_1",
        "title": ``,
        "caption": `Leafy Seadragon`,
        "exposure":  "1/100 sec at f / 14",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "JorgenRasmussenPC08",
        "title": ``,
        "caption": `Porcelain crab with eggs on seapen`,
        "exposure":  "1/125 sec at f / 36",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC5551_1",
        "title": ``,
        "caption": `At the end of a dive, we found this anemone with 3 very active little clown fish. They were opening their mouth repeatedly as if they were singing. The magnificent anemone made the perfect stage. 

The story behind the photo is however one of symbiotic and parasitic life. Clown fish lives inside the stinging tentacles of the host anemone. In return, it keeps the anemone clean and free of parasites. These clownfish however aren't as lucky. If you look closer, you will see a white animal inside the mouth of the clownfish to the right. This animal is a tongue parasite (Cymothoa exigua) which extracts blood from the tongue and then replaces it by attaching its own body to the stub. The fish is able to use the parasite just like a normal tongue. This is the only known case where a parasitic animal replaces an organ of the host animal. While the clownfish can't sing, they still survive this extraordinary transformation.`,
        "exposure":  "1/100 sec at f / 36",
        "location": "",
        "aspectRatio": 1.7777777777778      } ,       
          {
        "id": "JRASMUSSEN_1_2",
        "title": ``,
        "caption": `The Holy Grail of Fishes - The Rhinopia -  - Winner: Merit of Excellence, Celebrate the Sea 2007`,
        "exposure":  "1/100 sec at f / 29",
        "location": "",
        "aspectRatio": 2      } ,       
          {
        "id": "IMG_0723",
        "title": ``,
        "caption": `Mafia Smile - The Crocodile Fish from a new angle`,
        "exposure":  "1/100 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen_rasmussen_AAA3914",
        "title": ``,
        "caption": `Hairy Squat Lobster`,
        "exposure":  "1/125 sec at f / 38",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC5733",
        "title": ``,
        "caption": `Whitemargin Stargazer (Uranoscopus sulphureus)`,
        "exposure":  "1/100 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen_rasmussen_AAA8678",
        "title": ``,
        "caption": `Couple of tigershrimps on sponge with firery red crinoid as backdrop - the perfect stage!`,
        "exposure":  "1/125 sec at f / 38",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC1542",
        "title": ``,
        "caption": ``,
        "exposure":  "1/320 sec at f / 36",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "JRASMUSSEN_1_3",
        "title": ``,
        "caption": `Tiger Shrimp Couple on yellow sponge.  - Winner: Merit of Excellence, Celebrate the Sea 2007`,
        "exposure":  "1/100 sec at f / 20",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "IMG_4768_1",
        "title": ``,
        "caption": `Painted Frogfish`,
        "exposure":  "1/100 sec at f / 29",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "IMG_6335",
        "title": ``,
        "caption": `Green Solid Ghost Pipefish`,
        "exposure":  "1/100 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "IMG_8861_1",
        "title": ``,
        "caption": `Hairy Frogfish`,
        "exposure":  "1/80 sec at f / 18",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "IMG_9018",
        "title": ``,
        "caption": `Devil Stinger Fish`,
        "exposure":  "1/100 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "IMG_9043",
        "title": ``,
        "caption": `Maiting Mandarin Fish`,
        "exposure":  "1/100 sec at f / 11",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "img_9132",
        "title": ``,
        "caption": `Shrimp in Anemone`,
        "exposure":  "1/100 sec at f / 29",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "img_9194",
        "title": ``,
        "caption": `Stribed Hairy Frogfish`,
        "exposure":  "1/100 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "img_9284",
        "title": ``,
        "caption": `Velvet Ghost Pipefish`,
        "exposure":  "1/100 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "RASMUSSEN-Jorgen-02",
        "title": ``,
        "caption": `peacock mantis shrimp with eggs`,
        "exposure":  "1/100 sec at f / 29",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "img_9721",
        "title": ``,
        "caption": `Mimic Octopus - flounder style`,
        "exposure":  "1/100 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "img_9810",
        "title": ``,
        "caption": `Ornate Ghost Pipefish`,
        "exposure":  "1/100 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "MU-Rasmussen-Jorgen-4",
        "title": ``,
        "caption": `Couple of Pontohi Pygmy Seahorses - worlds smallest seahorse.  Approx. 10mm. Recently discovered`,
        "exposure":  "1/80 sec at f / 32",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "MU-Rasmussen-Jorgen-5",
        "title": ``,
        "caption": `Nudibranch`,
        "exposure":  "1/100 sec at f / 32",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "IMG_0531",
        "title": ``,
        "caption": `Rhinopia`,
        "exposure":  "1/100 sec at f / 20",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "IMG_0659",
        "title": ``,
        "caption": `Blue Ringed Octopus`,
        "exposure":  "1/100 sec at f / 32",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "IMG_0711",
        "title": ``,
        "caption": `Nudibranch`,
        "exposure":  "1/100 sec at f / 32",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "IMG_0784",
        "title": ``,
        "caption": `Flamboyant Cuddlefish`,
        "exposure":  "1/100 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "IMG_0785",
        "title": ``,
        "caption": `Flounder`,
        "exposure":  "1/100 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "IMG_0998",
        "title": ``,
        "caption": `Unidentified Frogfish`,
        "exposure":  "1/100 sec at f / 32",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC2563",
        "title": ``,
        "caption": `Anemone Fish`,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC2672",
        "title": ``,
        "caption": `Emperial Shrimp on Nudibranch`,
        "exposure":  "1/100 sec at f / 36",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-_DSC3314",
        "title": ``,
        "caption": `Shrimp in Bubble Coral`,
        "exposure":  "1/100 sec at f / 45",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "jorgen-rasmussen-_DSC3738",
        "title": ``,
        "caption": `Cow Fish`,
        "exposure":  "1/100 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen_rasmussen_AAA3886",
        "title": ``,
        "caption": `Eye Contact with 'Pregnant' pygmy seahorse`,
        "exposure":  "1/125 sec at f / 45",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "jorgen_rasmussen_AAA5439",
        "title": ``,
        "caption": `Bobtail squid (Euprymna berryi) approx. 15mm`,
        "exposure":  "1/125 sec at f / 45",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen_rasmussen_AAA5486",
        "title": ``,
        "caption": `Denise Pygmy Seahorse`,
        "exposure":  "1/250 sec at f / 45",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen_rasmussen_AAA5588",
        "title": ``,
        "caption": `Shrimps`,
        "exposure":  "1/125 sec at f / 38",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "jorgen_rasmussen_AAA5659",
        "title": ``,
        "caption": `Catfish`,
        "exposure":  "1/125 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen_rasmussen_AAA6044",
        "title": ``,
        "caption": `Harlequin Shrimp on Sponge`,
        "exposure":  "1/180 sec at f / 45",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen_rasmussen_AAA6233",
        "title": ``,
        "caption": `Scotch Eyes - Durban Shrimp`,
        "exposure":  "1/125 sec at f / 27",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "jorgen_rasmussen_AAA8062",
        "title": ``,
        "caption": `Coleman Shrimps on Fire Urchin`,
        "exposure":  "1/125 sec at f / 38",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_AAA8267",
        "title": ``,
        "caption": `Rare bumblebee shrimp`,
        "exposure":  "1/125 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen_rasmussen_AAA8391",
        "title": ``,
        "caption": ``,
        "exposure":  "1/125 sec at f / 38",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_AAA8777",
        "title": ``,
        "caption": `Tiger nudibranch`,
        "exposure":  "1/125 sec at f / 32",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_AAA8833",
        "title": ``,
        "caption": `crinoid squat lobster`,
        "exposure":  "1/125 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen_rasmussen_AAA8960",
        "title": ``,
        "caption": `Yellow soft coral goby`,
        "exposure":  "1/125 sec at f / 32",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_AAA8982",
        "title": ``,
        "caption": `Crinoid shrimp`,
        "exposure":  "1/125 sec at f / 32",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR13551",
        "title": ``,
        "caption": `Crinoid shrimp on crinoid`,
        "exposure":  "1/100 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "JorgenRasmussenPC09",
        "title": ``,
        "caption": `Porcelain crab on seapen`,
        "exposure":  "1/100 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR14216",
        "title": ``,
        "caption": `Ghost goby on sea whisp`,
        "exposure":  "1/125 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR14404",
        "title": ``,
        "caption": `Imperial shrimps on seacucumber`,
        "exposure":  "1/100 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR14606",
        "title": ``,
        "caption": `Shrimp on starfish`,
        "exposure":  "1/200 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR14618",
        "title": ``,
        "caption": `Pink anemonefish`,
        "exposure":  "1/320 sec at f / 29",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR15134",
        "title": ``,
        "caption": `Banded pipefish`,
        "exposure":  "1/200 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC4518",
        "title": ``,
        "caption": `Much-desired Flabellina (Flabellindae)`,
        "exposure":  "1/100 sec at f / 36",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC4830",
        "title": ``,
        "caption": ``,
        "exposure":  "1/100 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC5057",
        "title": ``,
        "caption": `Weedy Pygmy Seahorse (H. Pontohi)`,
        "exposure":  "1/100 sec at f / 40",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC5789",
        "title": ``,
        "caption": `Coconut Octopus (Amphioctopus marginatus)`,
        "exposure":  "1/160 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC5823",
        "title": ``,
        "caption": `Spiny Devilfish (Inimicus didactylus)`,
        "exposure":  "1/125 sec at f / 36",
        "location": "Indonesia",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC6371",
        "title": ``,
        "caption": `Dusky Nembrotha (Polyceridae)`,
        "exposure":  "1/125 sec at f / 36",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC9398",
        "title": ``,
        "caption": `Mushroom Coral Ghost Shrimp (Periclimenes kororensis) with eggs`,
        "exposure":  "1/200 sec at f / 36",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC9591",
        "title": ``,
        "caption": `Eye of crocodile fish (Cymbacephalus beauforti)`,
        "exposure":  "1/250 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC9655",
        "title": ``,
        "caption": `Wire Coral Shrimp (Pontonides unciger)`,
        "exposure":  "1/320 sec at f / 20",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC9877",
        "title": ``,
        "caption": `Unidentified crab (approx. 5mm). E-mail me if you know what it is.`,
        "exposure":  "1/250 sec at f / 40",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC2080",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 36",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC2582",
        "title": ``,
        "caption": ``,
        "exposure":  "1/100 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC2652",
        "title": ``,
        "caption": ``,
        "exposure":  "1/100 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3144",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 36",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3734",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 45",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC4946",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC5633",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 36",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-12225",
        "title": ``,
        "caption": ``,
        "exposure":  "1/100 sec at f / 29",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-13189",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 40",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR13908",
        "title": ``,
        "caption": ``,
        "exposure":  "1/125 sec at f / 36",
        "location": "Indonesia",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "jorgen-rasmussen-14222",
        "title": ``,
        "caption": ``,
        "exposure":  "1/125 sec at f / 40",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-14774",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 36",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC0855",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 40",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC0969",
        "title": ``,
        "caption": ``,
        "exposure":  "1/320 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC1173",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 36",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC1381",
        "title": ``,
        "caption": ``,
        "exposure":  "1/160 sec at f / 36",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC1880",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      }      
      ]}