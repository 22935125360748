export default {
  "useFlickr": "false",
	"galleryTitle": "JØRGEN RASMUSSEN | PHOTOGRAPHY  Gallary: Site Title",
	"textColor": "rgba(255, 255, 255, 1)",
	"thumbFrameColor": "rgba(255, 255, 255, 0.5)",
	"showOpenButton": "true",
	"showExpandButton": "true",
	"useFullscreenExpand": "true",
	"enableDirectLinks": "true",
	"expandInNewPage": "false",
	"topAreaHeight": "0",
	"galleryFontFace": "'Helvetica Neue', Helvetica, Arial, 'lucida grande', tahoma, verdana, arial, sans-serif;",
	"galleryDescription": "Gallery description",
  "galleryTitlePosition": "NONE",
	"showThumbsButton": "true",
	"captionBackColor": "rgba(0,0,0,0)",
	"maxThumbRows": "1",
	"maxThumbColumns": "5",
	"showThumbsOnLoad": "false",
	"showSmallThumbsOnLoad": "false",
	"showSmallThumbsButton": "false",
	"showPagingText": "false",
	"thumbNavPosition": "CENTER",
	"buttonBarHAlign": "RIGHT",
	"showInfoButton": "false",
	"showNavButtons": "false",
	"buttonBarIconSize": "25",
	"captionPosition": "OVERLAY_IMAGE",
	"maxCaptionHeight": "320",
	"showImageNumber": "true",
	"shareFacebook": "true",
	"shareTwitter": "true",
	"shareGPlus": "false",
	"sharePinterest": "true",
	"shareTumblr": "false",
	"shareUrl": "http://www.jorgen-rasmussen.com/portfolio/",
	"addSEOContent": "false",
	"backButtonPosition": "OVERLAY",
	"backButtonUseIcon": "true",
  "backButtonUrl": "http://www.jorgen-rasmussen.com",
  "showSmallBackButton": "true",
	"changeImageOnHover": "false",
	"navButtonIconSize": "20",
	"showSmallPagingText": "false",
	"navButtonBackColor": "rgba(255,255,255,0)",
	"textShadowColor": "rgba(0,0,0,.5)",
	"buttonBarBackColor": "rgba(0,0,0,0)",
	"imageFrameColor": "rgba(255,255,255,0)",
	"buttonBarIconHoverColor": "rgba(204,204,204,1)",
	"buttonBarIconColor": "rgba(255,255,255,.95)",
	"imageScaleMode": "SCALE",
	"buttonBarPosition": "OVERLAY",
	"backButtonHAlign": "LEFT",
	"useLargeImages": "true",
	"useSmallImages": "true",
  "images": [          {
        "id": "_JR23577",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC0858",
        "title": ``,
        "caption": `Humpback Whale`,
        "exposure":  "1/100 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "RASMUSSEN-Jorgen-01",
        "title": ``,
        "caption": `Family portrait - humpback whale mother, calf and escort`,
        "exposure":  "1/200 sec at f / 7.1",
        "location": "Indonesia",
        "aspectRatio": 1.4883720930233      } ,       
          {
        "id": "_JR19981",
        "title": ``,
        "caption": `Playful Humpback whale calf`,
        "exposure":  "1/250 sec at f / 5.6",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR10026",
        "title": ``,
        "caption": `Playful Humpback whale calf`,
        "exposure":  "1/250 sec at f / 5.6",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR19904",
        "title": ``,
        "caption": `Playful Humpback whale calf`,
        "exposure":  "1/200 sec at f / 5.0",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR19547",
        "title": ``,
        "caption": `Humpback whale mother and calf`,
        "exposure":  "1/250 sec at f / 6.3",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "JRasmussenAB2",
        "title": ``,
        "caption": `Humpback whale calf. Freediver: Amber Bourke`,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "Indonesia",
        "aspectRatio": 1.4883720930233      } ,       
          {
        "id": "JRasmussen06",
        "title": ``,
        "caption": `Humpback whale calf. Freediver: Amber Bourke`,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR10228",
        "title": ``,
        "caption": `Humpback whale calf. Freediver: Amber Bourke`,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "Indonesia",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR12236",
        "title": ``,
        "caption": `Humpback whale calves are courious and will often come up and check you out.`,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR12254-Edit",
        "title": ``,
        "caption": `Humpback whale mother and calf. Big tail small tail.`,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR12575",
        "title": ``,
        "caption": `Pilot whale. Contrast and patterns.`,
        "exposure":  "1/250 sec at f / 2.8",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "JorgenRasmussenPC03",
        "title": ``,
        "caption": `Humpback Whale Mother and Calf. Synchronised swimming.`,
        "exposure":  "1/200 sec at f / 8.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "JorgenRasmussenBWP01",
        "title": ``,
        "caption": `Humpback whale mother and calf. Belly up`,
        "exposure":  "1/200 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "JorgenRasmussenPC04",
        "title": ``,
        "caption": `Humpback whale showing belly.`,
        "exposure":  "1/200 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "JorgenRasmussenCP02",
        "title": ``,
        "caption": `Humpback Whale Mother and Calf. Synchronised swimming.`,
        "exposure":  "1/200 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR10843",
        "title": ``,
        "caption": `Humpback whale mother and calf`,
        "exposure":  "1/200 sec at f / 7.1",
        "location": "Indonesia",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC0622",
        "title": ``,
        "caption": `Humpback Whale`,
        "exposure":  "1/100 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC1389",
        "title": ``,
        "caption": `Humpback Whale`,
        "exposure":  "1/125 sec at f / 4.5",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC1416",
        "title": ``,
        "caption": `Humpback Whale`,
        "exposure":  "1/125 sec at f / 4.5",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "JorgenRasmussenBWP02",
        "title": ``,
        "caption": `Humpback whale mother and calf. Calf was sleeping and mother gently lifted it to the surface to breath. We spent more than an hour with this couple - truly amazing.`,
        "exposure":  "1/160 sec at f / 9.0",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR10831_1_copy",
        "title": ``,
        "caption": `Humpback whale mother and calf. Calf was sleeping and mother gently lifted it to the surface to breath. We spent more than an hour with this couple - truly amazing.`,
        "exposure":  "1/160 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR12324",
        "title": ``,
        "caption": `Humpback whale calves are courious and will often come up and check you out.`,
        "exposure":  "1/250 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR12334-Edit",
        "title": ``,
        "caption": `Humpback whale mother and calf. Calfs are almost white at birth.`,
        "exposure":  "1/250 sec at f / 5.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "ballet_of_the_giants",
        "title": ``,
        "caption": `Humpback Whale`,
        "exposure":  "1/160 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "yin_and_yang",
        "title": ``,
        "caption": `Humpback Whale`,
        "exposure":  "1/160 sec at f / 7.1",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "ballet_of_the_giants_2",
        "title": ``,
        "caption": `Humpback Whale`,
        "exposure":  "1/160 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      }      
      ]}