const aboutGalleries = [
  {id:'jorgen-rasmussen-9160', subTitle: 'Jorgen photographing seals in Antarctica' },
  {id:'jorgen-rasmussen-9130', subTitle: 'Jorgen photographing seals in Antarctica' },
  {id:'jorgen-rasmussen-13413', subTitle: 'Jorgen photographing seals in Antarctica' },
  {id:'_DSC0277_1', subTitle: 'Jorgen\'s custom-built 48 foot private sailing catamaran Songlines.' },
  {id:'jorgen-rasmussen-6986', subTitle: 'Jorgen photographing Tigersharks. Photo by Sott Portelli.' },
  {id:'jorgen-rasmussen-12175', subTitle: 'Up close and personal with tiger shark' },
  {id:'171148_487953352191_647532_o', subTitle: 'Jorgen photographing humpback whales. Photo by Scott Portelli' },
  {id:'524393_10151538975397192_810408394_n', subTitle: 'Jorgen with one of his pictures at the Elysium exhibition at the National Maritime Museum' },
  {id:'10371725_10152173828071969_2041274539574690043_n', subTitle: 'Photographing in Wester Australia' }
]
export default aboutGalleries

