export default {
  "useFlickr": "false",
	"galleryTitle": "JØRGEN RASMUSSEN | PHOTOGRAPHY  Gallary: Site Title",
	"textColor": "rgba(255, 255, 255, 1)",
	"thumbFrameColor": "rgba(255, 255, 255, 0.5)",
	"showOpenButton": "true",
	"showExpandButton": "true",
	"useFullscreenExpand": "true",
	"enableDirectLinks": "true",
	"expandInNewPage": "false",
	"topAreaHeight": "0",
	"galleryFontFace": "'Helvetica Neue', Helvetica, Arial, 'lucida grande', tahoma, verdana, arial, sans-serif;",
	"galleryDescription": "Gallery description",
  "galleryTitlePosition": "NONE",
	"showThumbsButton": "true",
	"captionBackColor": "rgba(0,0,0,0)",
	"maxThumbRows": "1",
	"maxThumbColumns": "5",
	"showThumbsOnLoad": "false",
	"showSmallThumbsOnLoad": "false",
	"showSmallThumbsButton": "false",
	"showPagingText": "false",
	"thumbNavPosition": "CENTER",
	"buttonBarHAlign": "RIGHT",
	"showInfoButton": "false",
	"showNavButtons": "false",
	"buttonBarIconSize": "25",
	"captionPosition": "OVERLAY_IMAGE",
	"maxCaptionHeight": "320",
	"showImageNumber": "true",
	"shareFacebook": "true",
	"shareTwitter": "true",
	"shareGPlus": "false",
	"sharePinterest": "true",
	"shareTumblr": "false",
	"shareUrl": "http://www.jorgen-rasmussen.com/portfolio/",
	"addSEOContent": "false",
	"backButtonPosition": "OVERLAY",
	"backButtonUseIcon": "true",
  "backButtonUrl": "http://www.jorgen-rasmussen.com",
  "showSmallBackButton": "true",
	"changeImageOnHover": "false",
	"navButtonIconSize": "20",
	"showSmallPagingText": "false",
	"navButtonBackColor": "rgba(255,255,255,0)",
	"textShadowColor": "rgba(0,0,0,.5)",
	"buttonBarBackColor": "rgba(0,0,0,0)",
	"imageFrameColor": "rgba(255,255,255,0)",
	"buttonBarIconHoverColor": "rgba(204,204,204,1)",
	"buttonBarIconColor": "rgba(255,255,255,.95)",
	"imageScaleMode": "SCALE",
	"buttonBarPosition": "OVERLAY",
	"backButtonHAlign": "LEFT",
	"useLargeImages": "true",
	"useSmallImages": "true",
  "images": [          {
        "id": "JRasmussenFB02",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06069",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 5.6",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR03843",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "JRasmussenPA08",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "Jorgen_Rasmussen_Color_Print",
        "title": `Sailfish – the hunt`,
        "caption": `Isla Mujeres, MexicoSailfish are the world’s fastest fish and swims as fast as chetahs run. Speed is important for sailfish but every part of this amazing creature has a function. When cruising, sailfish are silver and the extraordinary dorsal fin lays flat making them perfectly camouflaged against the blue ocean. Working in large groups, sailfish chase sardines from deeper waters to the surface. When hunting, sailfish change their pale body color to dark brown with electric blue and purple pulsing patterns. By raising their sail-like dorsal fin they block the escape routes of their prey until they form a tight ball. This is when the real frenzy starts. While some of the group blocks escape routes, others start feeding. They use they long bill not as a spear but more like a knife. With precision, they pick out a sardine from the ball. With a quick head flick, they injure the sardine badly. All left to do is to pick up the injured fish. Truly amazing!`,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "Jorgen_Rasmussen_Black_White",
        "title": `Sailfish stand-off`,
        "caption": `Sailfish stand-off`,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR03828",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR03864",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR03981",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR03999",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04012",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04109",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04186",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04187",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04318",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04400",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05005",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05553",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05588",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05653",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05670",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05802",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05830",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06005",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR03722",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR03733",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      }      
      ]}