import { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import makeStyles from "@material-ui/styles/makeStyles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


// Icons
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import ContactIcon from '@material-ui/icons/AlternateEmail';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import AppsIcon from '@material-ui/icons/Apps'; 

// TODO Clean out unused classes from all pages and components

const useStyles = makeStyles(theme => ({
    root: {
        position: "sticky",
        backgroundColor: "rgba(255, 255, 255, 0.87)",
        color: "rgba(0, 0, 0, 0.87)",
        zIndex: "7",
        height: "4rem",
    },
    content: {
        padding: theme.spacing(0),
        position: "relative",
        display: "flex",
        justifyContent: 'space-between',
        alignItems: "center",
        height: "100%",
        
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 2),
        },
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 1),
        },
    },
    contentRight: {
        position: "absolute",
        right: theme.spacing(0),
        display: "flex",
        alignItems: "center",

        [theme.breakpoints.down("md")]: {
            right: theme.spacing(4),
        },
        [theme.breakpoints.down("sm")]: {
            right: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            right: theme.spacing(0.25),
        },
    },
    linkLogo: {
        display: "flex",
        alignItems: "center",

        "&:hover": {
            textDecoration: "none", 
        },
    },
    logo: {
        marginLeft: theme.spacing(0),
        // maxWidth: '300',
        // width: "2.5rem",
        width: 320,
        // [theme.breakpoints.down("md")]: {
        //     marginLeft: theme.spacing(2),
        // },
        [theme.breakpoints.down("xs")]: {
            // marginLeft: theme.spacing(1),
            width: '80%',
        },
    },
    link: {
        color: "rgba(0, 0, 0, 0.87)",

        "&:hover": {
            color: "#156387",
            textDecoration: "none",
            cursor: "pointer",
        },

    },
    linkDrawer: {
        padding: "0",
        display: "none",
        backgroundColor: "#ddd",
        textDecoration: "none",
        color: "rgba(0, 0, 0, 0.87)",

        "&:hover": {
            color: "#156387",
            textDecoration: "none",
            cursor: "pointer",
        },
        
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            margin: theme.spacing(10, 0, 2, 0),
            width: "100%",
        },
    },
    linkItem: {
        textDecoration: "none",
        color: "rgba(0, 0, 0, 0.87)",
        "&:hover": {
            textDecoration: "none",
            cursor: "pointer",
            color: "#156387",
        },
    },
    wrapper: { // override Tab wrapper style
        display: "block",
        padding: theme.spacing(0, 10.5),
        textAlign: "left",
        
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(0, 8),
        },
    },
    indicator: { // override Tabs indicator style
        [theme.breakpoints.down("sm")]: {
            left: theme.spacing(3),
        },
        [theme.breakpoints.down("xs")]: {
            left: theme.spacing(2),
        },
    },
   
    
    sideNavItem: {
        padding: theme.spacing(1,6,1,2),

        // [theme.breakpoints.down("sm")] : {
        //     padding: theme.spacing(1,1)
        // },
        // [theme.breakpoints.down("xs")] : {
        //     padding: theme.spacing(1,1)
        // },
    }
    // button: {
    // //     width: "148px",
    //     textTransform: "uppercase",
        
    // //     [theme.breakpoints.down("xs")]: {
    // //         width: "100px",
    // //         padding: theme.spacing(0.7, 0.5),
    // //         fontSize: "0.6rem",
    // //     },
    // },
}))


const ListItemLink = ( { 
    icon, 
    text, 
    ...rest 
    } ) => {
    const classes = useStyles();
    return (
      <ListItem 
          className={classes.sideNavItem}
          button 
          component="a" 
          {...rest}
      >
          <ListItemIcon>
          {icon}
          </ListItemIcon>
          <ListItemText primary={text} />
      </ListItem>
    )
}
const TopBar = ({containerRef}) => {
    
    const classes = useStyles();        
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleOpenMenu = () => {
        setDrawerOpen(!drawerOpen);
    };
    return (        
        <AppBar className={classes.root} elevation={0}>
            <Toolbar className={classes.content}>
                <Link href="/#" className={classes.linkLogo}>
                    {/* <img src={"/images/logo_black.svg"} alt="SafeDay Logo" className={classes.logo} /> */}
                    <img src="/images/logo_black.png" alt="JØRGEN RASMUSSEN" className={classes.logo} />
                </Link>        
                <IconButton size="large" onClick={handleOpenMenu}> 
                        <MenuIcon />        
                </IconButton>                
            </Toolbar>
            <Drawer 
                anchor="right"
                elevation={6}
                open={drawerOpen}
                onClose={() => setDrawerOpen()}            
            >
                <List component="nav" aria-label="sideBar">
                    <ListItemLink                         
                        href="/#"
                        onClick={() => setDrawerOpen()}
                        icon={<HomeIcon/>}
                        text="Home"
                    />                                        
                    <ListItemLink                         
                        href="/#galleries"
                        onClick={() => setDrawerOpen()}
                        icon={<AppsIcon/>}
                        text="Gallery"
                    />                                        
                    <ListItemLink                         
                        href="/#about"
                        onClick={() => setDrawerOpen()} 
                        icon={<InfoIcon/>}
                        text="About"
                    />                                        
                    <ListItemLink                         
                        href="/#contact"
                        onClick={() => setDrawerOpen()} 
                        icon={<ContactIcon/>}
                        text="Contact"
                    />                                        
                    <ListItemLink
                        // iOS: opens facebook but not the profile
                        // Web: doesn't work
                        // href={"fb://page/" +  encodeURI('jorgen.rasmussen.1232') } 
                        href="fb://profile/707407191" 

                        
                        // iOS: opens facebook but not the profile
                        // Web: doesn't work
                        // href={"fb://profile/" +  encodeURI('jorgen.rasmussen.1232') } 




                        // href="https://www.facebook.com/jorgen.rasmussen.1232/" // works on browser but tries to log user in on iOS
                        target="_blank"
                        onClick={() => setDrawerOpen()} 
                        icon={<FacebookIcon/>}
                        text="Facebook"
                    />                                        
                    <ListItemLink                         
                        href="https://www.instagram.com/jorgenphotography/"
                        onClick={() => setDrawerOpen()}  
                        icon={<InstagramIcon/>}
                        target="_blank"
                        text="Instagram"
                    />                        
                </List>
            </Drawer>
        </AppBar>
    )
}

export default TopBar;