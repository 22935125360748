export default {
  "useFlickr": "false",
	"galleryTitle": "JØRGEN RASMUSSEN | PHOTOGRAPHY  Gallary: Site Title",
	"textColor": "rgba(255, 255, 255, 1)",
	"thumbFrameColor": "rgba(255, 255, 255, 0.5)",
	"showOpenButton": "true",
	"showExpandButton": "true",
	"useFullscreenExpand": "true",
	"enableDirectLinks": "true",
	"expandInNewPage": "false",
	"topAreaHeight": "0",
	"galleryFontFace": "'Helvetica Neue', Helvetica, Arial, 'lucida grande', tahoma, verdana, arial, sans-serif;",
	"galleryDescription": "Gallery description",
  "galleryTitlePosition": "NONE",
	"showThumbsButton": "true",
	"captionBackColor": "rgba(0,0,0,0)",
	"maxThumbRows": "1",
	"maxThumbColumns": "5",
	"showThumbsOnLoad": "false",
	"showSmallThumbsOnLoad": "false",
	"showSmallThumbsButton": "false",
	"showPagingText": "false",
	"thumbNavPosition": "CENTER",
	"buttonBarHAlign": "RIGHT",
	"showInfoButton": "false",
	"showNavButtons": "false",
	"buttonBarIconSize": "25",
	"captionPosition": "OVERLAY_IMAGE",
	"maxCaptionHeight": "320",
	"showImageNumber": "true",
	"shareFacebook": "true",
	"shareTwitter": "true",
	"shareGPlus": "false",
	"sharePinterest": "true",
	"shareTumblr": "false",
	"shareUrl": "http://www.jorgen-rasmussen.com/portfolio/",
	"addSEOContent": "false",
	"backButtonPosition": "OVERLAY",
	"backButtonUseIcon": "true",
  "backButtonUrl": "http://www.jorgen-rasmussen.com",
  "showSmallBackButton": "true",
	"changeImageOnHover": "false",
	"navButtonIconSize": "20",
	"showSmallPagingText": "false",
	"navButtonBackColor": "rgba(255,255,255,0)",
	"textShadowColor": "rgba(0,0,0,.5)",
	"buttonBarBackColor": "rgba(0,0,0,0)",
	"imageFrameColor": "rgba(255,255,255,0)",
	"buttonBarIconHoverColor": "rgba(204,204,204,1)",
	"buttonBarIconColor": "rgba(255,255,255,.95)",
	"imageScaleMode": "SCALE",
	"buttonBarPosition": "OVERLAY",
	"backButtonHAlign": "LEFT",
	"useLargeImages": "true",
	"useSmallImages": "true",
  "images": [          {
        "id": "_JR06432",
        "title": ``,
        "caption": `Giant Oceanic Manta (Mobula birostris)`,
        "exposure":  "1/320 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06331",
        "title": ``,
        "caption": `Giant Oceanic Manta (Mobula birostris)`,
        "exposure":  "1/250 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR07194",
        "title": ``,
        "caption": `Giant Oceanic Manta (Mobula birostris)`,
        "exposure":  "1/320 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06391",
        "title": ``,
        "caption": `Giant Oceanic Manta (Mobula birostris)`,
        "exposure":  "1/320 sec at f / 8.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06400",
        "title": ``,
        "caption": `Giant Oceanic Manta (Mobula birostris)`,
        "exposure":  "1/320 sec at f / 8.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06415",
        "title": ``,
        "caption": `Giant Oceanic Manta (Mobula birostris)`,
        "exposure":  "1/320 sec at f / 8.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06477",
        "title": ``,
        "caption": `Giant Oceanic Manta (Mobula birostris)`,
        "exposure":  "1/320 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06505",
        "title": ``,
        "caption": `Giant Oceanic Manta (Mobula birostris)`,
        "exposure":  "1/320 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06571",
        "title": ``,
        "caption": `Giant Oceanic Manta (Mobula birostris)`,
        "exposure":  "1/200 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06638",
        "title": ``,
        "caption": `Giant Oceanic Manta (Mobula birostris)`,
        "exposure":  "1/250 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06993",
        "title": ``,
        "caption": `Giant Oceanic Manta (Mobula birostris)`,
        "exposure":  "1/250 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR07021",
        "title": ``,
        "caption": `Giant Oceanic Manta (Mobula birostris)`,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JBR8544",
        "title": ``,
        "caption": `Reef Manta (Mobula alfredi)`,
        "exposure":  "1/250 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "WU_JORGEN_RASMUSSEN_1",
        "title": ``,
        "caption": `Reef Manta (Mobula alfredi)`,
        "exposure":  "1/125 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen_rasmussen_aaa2133",
        "title": ``,
        "caption": `Reef Manta (Mobula alfredi)`,
        "exposure":  "1/180 sec at f / 13",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JBR8530",
        "title": ``,
        "caption": `Reef Manta (Mobula alfredi)`,
        "exposure":  "1/160 sec at f / 10",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JBR8591",
        "title": ``,
        "caption": `Reef Manta (Mobula alfredi)`,
        "exposure":  "1/400 sec at f / 18",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16847",
        "title": ``,
        "caption": `Reef Manta (Mobula alfredi)`,
        "exposure":  "1/200 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16855",
        "title": ``,
        "caption": `Reef Manta (Mobula alfredi)`,
        "exposure":  "1/250 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16898",
        "title": ``,
        "caption": `Reef Manta (Mobula alfredi)`,
        "exposure":  "1/200 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      }      
      ]}