import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import galleries from "data/galleries";
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link";

import InfoIcon from '@material-ui/icons/Info';
import ContactIcon from '@material-ui/icons/AlternateEmail';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2,5),
      marginTop: theme.spacing(5),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2,0)
      },
      backgroundColor: 'rgba(211,211,211,0.4)'
    },
    item: {
      padding: theme.spacing(.5,2),
      flexWrap: 'nowrap'
    },
    linkButton:{      
      fontWeight: 300,
      lineHeight: '1rem',
      fontSize: '.87rem',  
    },
    linkIcon: {
      padding: theme.spacing(0,1),
      fontSize: '1rem'
    },
    copyright: {
        width: "100%",
        position: "absolute",
        left: "0",
        color: "#666",
        textAlign: "center",
        padding: theme.spacing(3,0,6)
    },
}))

const SiteMapFooter = () => {

    const classes = useStyles();

    return (
      <Grid container className={classes.root}>
        <Grid item container xs="8" md="9" className={classes.container}>
          { galleries.flat().map( gallery =>
            <Grid item xs="6" sm="4" className={classes.item}>
              <Link href={`#galleries/${gallery.id}?`} className={classes.linkButton}>
                {gallery.title}
              </Link>
            </Grid>
          ) }
        </Grid>
        <Grid item xs="0" md="1">
        </Grid>
        <Grid item container xs="4" md="2">
          <Grid item container xs="12" className={classes.item}>
            <InfoIcon className={classes.linkIcon}/>
            <Link href="#about" className={classes.linkButton}>
                  About
            </Link>
          </Grid>
          <Grid item container xs="12" className={classes.item}>
            <ContactIcon className={classes.linkIcon}/>
            <Link href="#contact" className={classes.linkButton}>
                  Contact
            </Link>
          </Grid>
          <Grid item container xs="12" className={classes.item}>
            <FacebookIcon className={classes.linkIcon}/>
            <Link href="https://www.facebook.com/jorgen.rasmussen.1232/" className={classes.linkButton}>
                  Facebook
            </Link>
          </Grid>
          <Grid item container xs="12" className={classes.item}>
            <InstagramIcon className={classes.linkIcon}/>
            <Link href="https://www.instagram.com/jorgenphotography/" className={classes.linkButton}>
                  Instagram
            </Link>            
          </Grid> 
        </Grid>
        <Grid item xs="12">
          <Typography className={classes.copyright}>
            &copy; 2022 jorgenrasmussen.com
          </Typography>          
        </Grid> 
      </Grid>
    )
}

export default SiteMapFooter;