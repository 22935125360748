export default {
  "useFlickr": "false",
	"galleryTitle": "JØRGEN RASMUSSEN | PHOTOGRAPHY  Gallary: Site Title",
	"textColor": "rgba(255, 255, 255, 1)",
	"thumbFrameColor": "rgba(255, 255, 255, 0.5)",
	"showOpenButton": "true",
	"showExpandButton": "true",
	"useFullscreenExpand": "true",
	"enableDirectLinks": "true",
	"expandInNewPage": "false",
	"topAreaHeight": "0",
	"galleryFontFace": "'Helvetica Neue', Helvetica, Arial, 'lucida grande', tahoma, verdana, arial, sans-serif;",
	"galleryDescription": "Gallery description",
  "galleryTitlePosition": "NONE",
	"showThumbsButton": "true",
	"captionBackColor": "rgba(0,0,0,0)",
	"maxThumbRows": "1",
	"maxThumbColumns": "5",
	"showThumbsOnLoad": "false",
	"showSmallThumbsOnLoad": "false",
	"showSmallThumbsButton": "false",
	"showPagingText": "false",
	"thumbNavPosition": "CENTER",
	"buttonBarHAlign": "RIGHT",
	"showInfoButton": "false",
	"showNavButtons": "false",
	"buttonBarIconSize": "25",
	"captionPosition": "OVERLAY_IMAGE",
	"maxCaptionHeight": "320",
	"showImageNumber": "true",
	"shareFacebook": "true",
	"shareTwitter": "true",
	"shareGPlus": "false",
	"sharePinterest": "true",
	"shareTumblr": "false",
	"shareUrl": "http://www.jorgen-rasmussen.com/portfolio/",
	"addSEOContent": "false",
	"backButtonPosition": "OVERLAY",
	"backButtonUseIcon": "true",
  "backButtonUrl": "http://www.jorgen-rasmussen.com",
  "showSmallBackButton": "true",
	"changeImageOnHover": "false",
	"navButtonIconSize": "20",
	"showSmallPagingText": "false",
	"navButtonBackColor": "rgba(255,255,255,0)",
	"textShadowColor": "rgba(0,0,0,.5)",
	"buttonBarBackColor": "rgba(0,0,0,0)",
	"imageFrameColor": "rgba(255,255,255,0)",
	"buttonBarIconHoverColor": "rgba(204,204,204,1)",
	"buttonBarIconColor": "rgba(255,255,255,.95)",
	"imageScaleMode": "SCALE",
	"buttonBarPosition": "OVERLAY",
	"backButtonHAlign": "LEFT",
	"useLargeImages": "true",
	"useSmallImages": "true",
  "images": [          {
        "id": "_JR19108-4",
        "title": ``,
        "caption": `Atlantic Spotted Dolphin (Stenella frontalis)`,
        "exposure":  "1/160 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR19432",
        "title": ``,
        "caption": `Atlantic Spotted Dolphin (Stenella frontalis)`,
        "exposure":  "1/160 sec at f / 10",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR19365",
        "title": ``,
        "caption": `Atlantic Spotted Dolphin (Stenella frontalis)`,
        "exposure":  "1/200 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR19216-2",
        "title": ``,
        "caption": `Atlantic Spotted Dolphin (Stenella frontalis)`,
        "exposure":  "1/200 sec at f / 11",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR19251",
        "title": ``,
        "caption": `Atlantic Spotted Dolphin (Stenella frontalis)`,
        "exposure":  "1/200 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR19102",
        "title": ``,
        "caption": `Atlantic Spotted Dolphin (Stenella frontalis)`,
        "exposure":  "1/160 sec at f / 7.1",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "jrasmussen_aaa7074",
        "title": ``,
        "caption": `Dolphins feeding on Sardines`,
        "exposure":  "1/250 sec at f / 9.5",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jrasmussen_aaa7087",
        "title": ``,
        "caption": `Dolphins feeding on Sardines. Winner in  Antibes. 1st Prize, Underwater Reporter`,
        "exposure":  "1/250 sec at f / 9.5",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR07299",
        "title": ``,
        "caption": ``,
        "exposure":  "1/160 sec at f / 5.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR07318",
        "title": ``,
        "caption": ``,
        "exposure":  "1/160 sec at f / 5.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR27256",
        "title": ``,
        "caption": ``,
        "exposure":  "1/125 sec at f / 5.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR27064",
        "title": ``,
        "caption": ``,
        "exposure":  "1/100 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR26914",
        "title": ``,
        "caption": ``,
        "exposure":  "1/100 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR27199",
        "title": ``,
        "caption": ``,
        "exposure":  "1/160 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR26930",
        "title": ``,
        "caption": ``,
        "exposure":  "1/100 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      }      
      ]}