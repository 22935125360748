import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';

export default function SubmitErrorDialog({
  onClose,
  message
}) {
  return (
    <div>
      <Dialog
        onClose={onClose}
        open
      >
        <DialogTitle id="alert-dialog-title">Error</DialogTitle>
        <DialogContent>
          <div style={{padding:20, textAlign:'center', color:'red', fontSize:'70px'}}>
            <CancelIcon color="inherit" fontSize="inherit" />
          </div>
          <DialogContentText id="alert-dialog-description">
            An error occured and we were unable to receive the request. Please try again.
            <br/>
            <br/>
            {message}
            <br/>
            <br/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}