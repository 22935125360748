import React, {useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import AboutCarousel from "components/AboutCarousel";
import Grid from "@material-ui/core/Grid";
// Edit margin/padding, elements are edged

const useStyles = makeStyles(theme => ({
    contentContainer: {
        margin: theme.spacing(0,3)
    },
    header: {
        margin: theme.spacing(0,3)
    },
    paragraph: {
        fontSize: '1rem',
        // fontWeight: '100',
        margin: theme.spacing(3, 0),
        padding: theme.spacing(0, 0),
        textAlign: "centre",                
    },            
    portrait: {
        margin: theme.spacing(5,2),
        height: '500px',
        float: "right",

        [theme.breakpoints.down("md")] : {
            margin: theme.spacing(0,3),
            height: '400px',
        },

        [theme.breakpoints.down("sm")] : {
            margin: theme.spacing(4,3)
        },
        
        [theme.breakpoints.down("xs")] : {
            justifyContent: "centre",
            height: '300px',
        }
    },
    quote : {
        fontStyle: 'italic',
        fontSize: '21px',
        fontWeight: '100',
        padding: theme.spacing(5,5,5,5)         
    },
    awardsTitle: {
        marginTop: theme.spacing(5),
        fontSize: '2rem',
        fontWeight: '100',
    },
}))

const AboutPage = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
        <div className={classes.header}>
            <h1>About</h1>
        </div>
            <AboutCarousel/>
            <br/>
            <br/>
        <Grid container>
          <Grid item xs="12">
            <Typography>
              Growing up on the coast in Denmark, the ocean has always had a special place in Jorgen’s heart. When he was eight, Jorgen spent all his savings on his first windsurf board and used most of his childhood and youth windsurfing off the beautiful but freezing beaches along the danish coast.            
              <br/>
              <br/>
              After his studies, Jorgen started an international career. Work brought him to many exotic destinations including Borneo and Australia. Through documenting his journeys, Jorgen developed a passion for wildlife photography. 
            </Typography>
          </Grid>
          <Grid item>
            <img 
                className={classes.portrait} 
                src='/images/profile/Jorgen_Rasmussen_santi_og_seacam_camera.jpg' 
                alt="portraitHolding"
            />
            <Typography>                                
                It was on Australia’s Great Barrier Reef that Jorgen first felt in love with scuba diving and quickly qualified as a Dive Master. Soon after, he bought his first underwater camera. Capturing and sharing the underwater world became Jorgen’s biggest passion.
                <br/>
                <br/>
                Jane Goodall famously said that only if we understand can we care. We only protect things we care about. Our oceans and marine life is under extreme pressure due to overfishing, pollution and other human influences. Only few experience the underwater world first hand. It is our job as ambassadors of the ocean and nature photographer to help making people understand and care. <br/>
                Jorgen strive to produce imagery that make people connect with marine life and feel the ambience of the underwater world. He specialises in the large pelagics living in the open ocean/polar regions and larval stages of marine life living in the deep water (blackwater). He often uses ambient light to highlight and isolate the essence but something you will find in all Jorgen's pictures is an extreme focus on the posture of the marine life small or large. As wildlife can't be instructed, this can only be achieved by spending extended time photographing the various subjects. This however makes striking images that make people stop and take notice. 
                <br/>
                <br/>
                Encouraged by a photographic workshop, Jorgen started submitting pictures to international competitions. His imagery has won some of the world’s most prestigious awards including three first prizes at the World Underwater Image Festival, the Photographer of Festival at Celebrate the Sea and ADEX Photographer of the Year. ${/*In 2009, Jorgen was named photographer-in-residence at Ocean Geographic*/}.
                <br/>
                <br/>
                Blackwater photography require patient observation of behaviour at depth to capture the perfect moment. Scuba diving is ideal for that.
                Close encounters with large marine life however require agility and cautious approaches. Scuba equipment slows you down and bubbles is a problem for many large animals. Freediving or simply snorkelling are often the best approach. Jorgen's images of whales, dolphins, seals, sharks etc. are almost exclusively shot freediving.                
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.quote}>
                “When photographing large marine life, i found myself leaving the scuba gear behind. Freediving offered greater agility and allowed me to keep up with the action. My breath hold skills were however limiting. Training with Australian record-breaking freediver combined with regular ocean sessions has enabled me to freely interact with marine life such as dolphins on one breath – a truly liberating experience”. [Jorgen Rasmussen]
            </Typography>
          </Grid>
          <Grid item>
              <Typography>
                  Today, Jorgen is based in Singapore. He combines his exploration and photography passion with running his tech startup. He is a frequent speaker on international conferences on topics such as mobile app development, usability, visualisation and business intelligence. The Australian coastline is often explored on his 48 foot custom blue water sailing catamaran Songlines. This is combined with several annual expeditions to the worlds wildlife hot spots. The expeditions are generally focussed on a specific natural event and ranges from extreme polar sailing expeditions to more relaxed encounters in tropical waters. The technique and style of expedition is always chosen to maximise the photographic opportunity and would generally be a privately organised small group.
                  <br/>
                  <br/>
                  Recent developments in technologies offer opportunities to photograph wildlife from new and different angles. Jorgen is actively pursuing new ways to create engaging nature pictures.
              </Typography>
          </Grid>
          <Grid item xs="12">
            <Typography className={classes.awardsTitle}>
                International Photographic Awards
                <br/>
                <br/>
            </Typography>
          </Grid>
          <Grid item xs="12" md="6">
            <Typography>
                2023: Overall Winner, Master of Competion, Ocean Geographic Pictures of the Year
                <br/>
                <br/>
                2023: 1st Prize, Animal Behaviour – the DOUG PERRINE Award of Excellence, Ocean Geographic Pictures of the Year
                <br/>
                <br/>
                2023: 1st Prize, Master Portfolio – the DAVID DOUBILET Award of Excellence, Ocean Geographic Pictures of the Year
                <br/>
                <br/>
                2022: 1st Prize, Reportage, 2022 Underwater Guru Awards
                <br/>
                <br/>
                2015: 1st Prize, 2015 Nature Photographer of the Year, Australian Photography
                <br/>
                <br/>
                2013: 1st Prize, Behaviour - Ocean Art UPG
                <br/>
                <br/>
                2013: 1st Prize, Fish Behavior - Ocean Geographics, Picture of the Year 2013
                <br/>
                <br/>
                2013: 1st Prize, ADEX Photographer of the Year - Singapore
                <br/>
                <br/>
                2012: 1st Prize, Black and White Print - World Underwater Image Festival in Marseille
               </Typography>
          </Grid>
          <Grid item xs="12" md="6">
            <Typography>
                2012: 1st Prize, Humans - Underwater Festival - the Australasia Challenge
                <br/>
                <br/>
                2012: 2nd Prize, Underwater Festival - the Australasia Challenge
                <br/>
                <br/>
                2011: 1st Prize, Black and White Print - World Underwater Image Festival in Marseille
                <br/>
                <br/>              
                2011: 1st Prize, Overall winner - Photographer of Festival, Celebrate the Sea Image Festival
                <br/>
                <br/>
                2011: 1st Prize, Black and White Print, Celebrate the Sea Image Festival
                <br/>
                <br/>
                2010: 3rd Prize, Underwater Art Comptetition, Scubadiver Australasia Magazine
                <br/>
                <br/>
                2009: 3rd Prize, Rolex Portfolio of Festival, Celebrate the Sea Image Festival
                <br/>
                <br/>
                2008: 1st Prize, Underwater Reporter, World Underwater Image Festival in Antibes
                <br/>
                <br/>
                2008: 2nd Prize, Macro Unristricted Category, Our World Underwater
                <br/>
                <br/>
                2007: 2nd Prize, Digital Portfolio, Celebrate the Sea Image Festival
                <br/>
                <br/>
                2007: 1st Prize, Portolio, Ocean Geographic Shoot-out, Seraya, Bali
              </Typography>
            </Grid>
          </Grid>
        </>
    )
}

export default AboutPage; 
