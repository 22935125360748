import {useEffect} from "react";

import Carousel from "components/Carousel";
import PhotoGalleries from 'components/PhotoGalleries'
import Hidden from '@material-ui/core/Hidden';
const HomePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
      <>      
        <Hidden xsDown>
          <Carousel/>
        </Hidden>
        <PhotoGalleries />
      </> 
    );

};

export default HomePage;