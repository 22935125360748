import React from "react";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/styles/makeStyles";
import Image from "components/Image.js";
import { withSize } from 'react-sizeme'
import Grid from "@material-ui/core/Grid";

import galleries from "data/galleries";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },

  link: {
    "&:hover": {
        textDecoration: "none",
    },
  },
  // TODO : Possible culprit of horizontal Scroll identified using code below in browser console
  //   var docWidth = document.documentElement.offsetWidth;

  //   [].forEach.call(
  //   document.querySelectorAll('*'),
  //   function(el) {
  //     if (el.offsetWidth > docWidth) {
  //       console.log(el);
  //     }
  //   }
  // );
  card: {
      margin: -2,
      backgroundPosition: 'center', /* Center the image */
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',      
      border: '2px solid white',
      backgroundColor: 'black',      
      position:'relative',
      overflow:'hidden',
      color:'white',      
      '& h1': {
        fontWeight: 300,
        fontSize: '2rem',
        margin: theme.spacing(1),
        textShadow: '#000 2px 2px 10px'
      },
      '& p': {
        margin: theme.spacing(1),
        fontSize: '1rem',
        textShadow: '#000 1px 1px 6px'
      },
      "& img:hover": {
        opacity: .8
      },


    },
    textContainer:{
      position:'absolute', 
      top: 0, 
      left: 0, 
      padding: theme.spacing(1), 
    }
    
  }));


const Card = ( {
  id,
  title,
  subTitle,
  cols = 2,
  rows = 1,
  blockWidth,
  color = 'white'
} ) =>{
    const classes = useStyles();
    return (
      <Link href={`#galleries/${id}?`} className={classes.link}>
        <div
          className={classes.card} 
          style={ {
            width: blockWidth * cols,  
            height: blockWidth * rows,          
          } }
        >
          <Image width="100%" src={`images/tiles/${id}.jpg`} alt=""/>
          <div className={classes.textContainer} >
            <h1>{title}</h1>
            <p>{subTitle}</p>
          </div>
        </div>
      </Link>
  )
}

const PhotoGalleries = ( {
  size: { width } = {},
} ) =>{  
    console.log('render Galleries')
    const classes = useStyles();
    const targetBlocks = width > 800 ? 6 : width > 600 ? 4: 2 
    const blockWidth = width / targetBlocks
    
    return (
      <div className={classes.root}>
        <Grid container style={{overflow:'hidden'}}>
        { galleries.map( group =>
          <Grid item container style={{width: blockWidth*2}}>
            { group.map( gallery => 
              <Card
                {...gallery}
                blockWidth = {blockWidth}
              />
            ) }
          </Grid>
        ) }
        </Grid>
      </div>
  )

}
export default withSize()(PhotoGalleries)