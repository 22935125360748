export default {
  "useFlickr": "false",
	"galleryTitle": "JØRGEN RASMUSSEN | PHOTOGRAPHY  Gallary: Site Title",
	"textColor": "rgba(255, 255, 255, 1)",
	"thumbFrameColor": "rgba(255, 255, 255, 0.5)",
	"showOpenButton": "true",
	"showExpandButton": "true",
	"useFullscreenExpand": "true",
	"enableDirectLinks": "true",
	"expandInNewPage": "false",
	"topAreaHeight": "0",
	"galleryFontFace": "'Helvetica Neue', Helvetica, Arial, 'lucida grande', tahoma, verdana, arial, sans-serif;",
	"galleryDescription": "Gallery description",
  "galleryTitlePosition": "NONE",
	"showThumbsButton": "true",
	"captionBackColor": "rgba(0,0,0,0)",
	"maxThumbRows": "1",
	"maxThumbColumns": "5",
	"showThumbsOnLoad": "false",
	"showSmallThumbsOnLoad": "false",
	"showSmallThumbsButton": "false",
	"showPagingText": "false",
	"thumbNavPosition": "CENTER",
	"buttonBarHAlign": "RIGHT",
	"showInfoButton": "false",
	"showNavButtons": "false",
	"buttonBarIconSize": "25",
	"captionPosition": "OVERLAY_IMAGE",
	"maxCaptionHeight": "320",
	"showImageNumber": "true",
	"shareFacebook": "true",
	"shareTwitter": "true",
	"shareGPlus": "false",
	"sharePinterest": "true",
	"shareTumblr": "false",
	"shareUrl": "http://www.jorgen-rasmussen.com/portfolio/",
	"addSEOContent": "false",
	"backButtonPosition": "OVERLAY",
	"backButtonUseIcon": "true",
  "backButtonUrl": "http://www.jorgen-rasmussen.com",
  "showSmallBackButton": "true",
	"changeImageOnHover": "false",
	"navButtonIconSize": "20",
	"showSmallPagingText": "false",
	"navButtonBackColor": "rgba(255,255,255,0)",
	"textShadowColor": "rgba(0,0,0,.5)",
	"buttonBarBackColor": "rgba(0,0,0,0)",
	"imageFrameColor": "rgba(255,255,255,0)",
	"buttonBarIconHoverColor": "rgba(204,204,204,1)",
	"buttonBarIconColor": "rgba(255,255,255,.95)",
	"imageScaleMode": "SCALE",
	"buttonBarPosition": "OVERLAY",
	"backButtonHAlign": "LEFT",
	"useLargeImages": "true",
	"useSmallImages": "true",
  "images": [          {
        "id": "_JR15754",
        "title": ``,
        "caption": `Manatee (Trichechus manatus latirostris)`,
        "exposure":  "1/320 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR15863",
        "title": ``,
        "caption": `Manatee (Trichechus manatus latirostris)`,
        "exposure":  "1/60 sec at f / 7.1",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR16216",
        "title": ``,
        "caption": `Manatee (Trichechus manatus latirostris)`,
        "exposure":  "1/100 sec at f / 8.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16354",
        "title": ``,
        "caption": `Manatee (Trichechus manatus latirostris)`,
        "exposure":  "1/80 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16399-2",
        "title": ``,
        "caption": `Manatee (Trichechus manatus latirostris)`,
        "exposure":  "1/100 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR17254-3",
        "title": ``,
        "caption": `Manatee (Trichechus manatus latirostris)`,
        "exposure":  "1/80 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR17382",
        "title": ``,
        "caption": `Manatee (Trichechus manatus latirostris)`,
        "exposure":  "1/100 sec at f / 14",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR18523",
        "title": ``,
        "caption": `Manatee (Trichechus manatus latirostris)`,
        "exposure":  "1/100 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR18820",
        "title": ``,
        "caption": `Manatee (Trichechus manatus latirostris)`,
        "exposure":  "1/100 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      }      
      ]}