// TODO: Add description to be used under title in PhotoGallery. This should be a more detailed description that introduces the subject

// Galleries are split into an array of groups. Each group consist of 2 columns and 2 rows
// The groups can contain 1 or 2 photos depending on the columsn and rows.
const galleries = [
  [
    {id:'blackwater', title: 'Blackwater', subTitle: 'Unbelievable creatures from the deep', cols: 2, rows: 2 },
  ],
  // horisontal group
  [
    {id: 'billfish', title: 'Pelagics', subTitle: 'Worlds fastest fish: Swims as fast as cheetahs run', cols: 2, rows: 1},
    {id: 'sharks', title: 'Sharks', subTitle: 'Close encounters with the most misunderstood animals on Earth.', cols: 2, rows: 1 },
  ],
  [
    {id:'dolphins', title: 'Dolphins', subTitle: 'Connect with these social, intelligent and playful creatures in the open ocean', cols: 1, rows: 2 },
    {id:'rays', title: 'Rays', subTitle: 'With wingspans of up to 7m, rays are the oceans gliders', cols: 1, rows: 2 },
  ],
  [
    {id:'whales', title: 'Whales', subTitle: 'Getting up close with Earths largest mammals', cols: 2, rows: 1 },
    {id: 'other', title: 'Other Marine Mammals', subTitle: 'Manatees etc.', cols: 2, rows: 1}, // dugung
  ],
  [  
    {id:'seals', title: 'Seals & Sea Lions', subTitle: 'The most playfull animals in our oceans', cols: 2, rows: 2 },
  ],
  [
    {id: 'aerials', title: 'Aerials', subTitle: 'Experience Earths colors and patterns from a different perspective', cols: 2, rows: 1, color:'black' },
    {id: 'critters', title: 'Macro', subTitle: 'Be amazed of what the ocean holds when you look a little closer', cols: 2, rows: 1}
  ],
]
export default galleries