import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import Image from "components/Image";
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
    root: {
        margin: "0",
        width: "100%",
        alignContent: "center",
        position: "relative",
        overflow: "hidden",
        height: "640px",
        display: "inline-flex",
        backgroundColor: "black",

        [theme.breakpoints.down("md")]: {
            height: "50vw",
        },
    },
    textContainer: {
        position: "absolute",
        top: '10%',
        left: "0",
        paddingLeft: theme.spacing(6),
        width: "65%",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "pre-line",
        
        [theme.breakpoints.down("md")]: {
            paddingLeft: theme.spacing(6),
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(6),
            top: "20%",
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(4),
            top: theme.spacing(2),
            width: "70%",
        },
    },
    imageContainer: {
        display: "flex",
        flexShrink: "0",
        alignContent: "center",
        justifyContent: "center",
        overflow: "hidden",
        width: "100%",
        height: "640px",
        
        [theme.breakpoints.down("md")]: {
          height: "50vw",
        },

        '& img': {
            objectFit: "cover",
            width: "100%",
            overflow: "hidden",

            [theme.breakpoints.down("md")]: {
                height: "auto",
            },
        },
    },
    title: {
        fontWeight: 300,
        color: ({type}) => type === "white" ? "white" : "rgba(0, 0, 0, 0.87)",
        fontSize: "4rem",
        marginBottom: theme.spacing(1),
        textShadow: '#000 2px 2px 10px',
        [theme.breakpoints.down("md")]: {
            fontSize: "2.5rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "2.5rem",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "1.25rem",
        },
    },
    subtitle: {

        color: ({type}) => type === "white" ? "white" : "rgba(0, 0, 0, 0.87)",
        lineHeight: "1.5",
        fontSize: "1.7rem",
        textShadow: '#000 2px 2px 10px',        
        [theme.breakpoints.down("md")]: {
            width: "80%",
            fontSize: "1.25rem",
        },
        [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginTop: theme.spacing(2),
            fontSize: "1.125rem",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginTop: theme.spacing(1),            
            fontSize: "0.8rem",
        },
    },
    button:{
      color:'white',
      borderColor: 'white',
      marginTop: theme.spacing(3)
    }
}))

const Header = ({
  titleText, 
  subtitleText, 
  image, 
  type,  
  fontColor = 'white',
  action,
//   textPosition | Implement text possition so we can position them nicely depending on the background
}) => {
    const classes = useStyles({type});

    const UnloaderImage = () => {
        return (
            <Image src="images/placeholderIMG.jpeg" className={classes.imageContainer}/>
        );
    };

    return (
        <div className={classes.root} >
          <Image src={image} unloader={<UnloaderImage/>} className={classes.imageContainer} />
          <div className={classes.textContainer} >
            { titleText && <Typography variant="h1" className={classes.title} style={ { color: fontColor } }>{titleText}</Typography> }
            { subtitleText && <Typography variant="h2" className={classes.subtitle} style={ { color: fontColor } }>{subtitleText}</Typography> }
            { action && <Button size="large" variant="outlined" style={{color: fontColor, borderColor: fontColor }} className={classes.button}>{action}</Button> }
          </div>
        </div>
    )
}

export default Header;