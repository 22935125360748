import {useEffect} from "react";
import {
  useParams,
  useRouteMatch
} from "react-router-dom";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from "@material-ui/core/IconButton";
import galleries from 'data/galleries'
import PhotoGalleries from "components/PhotoGalleries";
import PhotoGallery from "components/PhotoGallery";
import makeStyles from "@material-ui/styles/makeStyles";



// Gallery data. These files are generated by the companion Lightroom plugin I created.
// When we generate the gallery, it creates the image in the different sizes required as well as a file called config.js.
// We copy all of it it the public/images/galleries/[galleryname]
// copy the config.js into the data folder, rename it to the gallary name and import it here and add it to the map
// This approach makes the data part of the build. While this makes the gallery file bigger, it eliminates loads on navigation and only images are loaded and therefore immidiate response
import dolphins from "../data/dolphins";
import billfish from "../data/billfish";
import seals from "../data/seals";
import sharks from "../data/sharks";
import rays from "../data/rays";
import critters from "../data/critters.js";

import blackwater from "../data/blackwater";
import aerials from "../data/aerials";
import whales from "../data/whales";
import other from "../data/other";

const useStyles = makeStyles(theme => ({
  header: {
    margin: theme.spacing(0,2)
  },
}))

const galleryMap = {
  dolphins,
  billfish,
  blackwater,
  aerials,
  seals,
  whales,
  sharks,
  rays,
  critters,
  other
}
  

const Page = () => {
    let { path, url } = useRouteMatch();
    let { galleryId } = useParams();
    // let [searchParams, setSearchParams] = useSearchParams();
    console.log({galleryId, path, url})

    const classes = useStyles()
    // Get Gallery Data based on path:
    const data = galleryMap[galleryId]
    const galleryInfo = galleries.flat().find(({id}) => id === galleryId)
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
        {!data && // If no data, show gallery overview
          <>
            <div className={classes.header}>
              <h1>Galleries</h1>
            </div>
            <PhotoGalleries />
          </>
        }
        {data &&
          <> 
          {galleryInfo &&            
              <h1>
                <IconButton href="#" edge="start">
                  <ChevronLeftIcon 
                    fontSize="large"
                  />
                </IconButton>
                {galleryInfo.title}
              </h1>
          }
          <PhotoGallery
            galleryId={galleryId}
            data={data} 
          />
          </> 
        }
        </>
    );
};  

export default Page;