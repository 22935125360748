import React, {useState} from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import {  withSize } from 'react-sizeme'
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'
import { Typography } from "@material-ui/core";

const DURATION = 1000;

const useStyles = makeStyles(theme => ({
  '@keyframes fadeIn': {
    '0%': { opacity:0 },
    '100%': { opacity:1 }
  },
  root:{
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  thumbnail: {
    opacity: 0,
    animationFillMode: 'forwards',
    width: "100%",
    animation: `$fadeIn ${DURATION}ms ${theme.transitions.easing.easeInOut}`,
    cursor: 'pointer',
    objectFit: 'cover',
    margin: '2px 2px',  
  },  
  p: {
    padding: theme.spacing(2),
  },  
  paper: {
    padding: theme.spacing(1),
  },  
  modalBackdrop : {
    zIndex: 100001, 
    position: 'fixed', 
    bottom: 0, 
    left: 0, 
    right: 0,   
    height: '100%',          
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'end', 
  },
  modalContent: {
    color: 'white', 
    backgroundColor: 'rgba(0,0,0,0.8)', 
    width: '100%',
    padding: theme.spacing(0,3)
  }
}));


const PhotoGallery = ( {
  galleryId,
  size: { width } = {},
  data
} ) =>
{  
  const [{modalDataIndex}, setModalDataIndex] = useState({})
  const [modalShown, toggleModal] = useState(false)
  const uiElements = [
    {
      name: 'custom-button-info',
      ariaLabel: 'Custom button Info',
      order: 9,
      isButton: true,      
      html: {
        isCustomSVG: true,
        inner:
          '<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" id="pswp__icn-cstm-btn"/>',      
        outlineID: 'pswp__icn-cstm-btn',
      },
      appendTo: 'bar',
      onInit: ( el, pswpInstance ) => {   
        pswpInstance.on('change', () => {
          // triggers when slide is switched, and at initialization
          setModalDataIndex({})          
          console.log('change');
        });
      },      
      onClick: ( e, el, pswpInstance ) => {          
        e.preventDefault() 
        const modalDataIndex = pswpInstance.currSlide.index
        toggleModal(!modalShown)
        setModalDataIndex({modalDataIndex})            
      }      
    },
  ]

  console.log('render Galleries')
  const classes = useStyles();
  if(!data?.images || !data.images.length) return null
  const targetBlocks = width > 800 ? 6 : width > 500 ? 4 : 3 
  const targetSize = parseInt(width / targetBlocks) - 4 
  // console.log( 'PhotoGallery', {width})
  
  const activeModalData = modalDataIndex !== undefined && data.images[modalDataIndex]

  console.log(activeModalData)

  const Modal = ( { children, shown, close} ) => {
    return shown ? (
      <div
        className={classes.modalBackdrop}
        onClick= {() => {
          // close modal when clicked outside
          close()
          console.log('backdropClicked')
        }}
      >
      <div   
        className={classes.modalContent}
        onClick= {(e) => {
          // do not close modal if anything inside modal is clicked
          e.stopPropagation()
          console.log('contentClicked')
        }}
      >
        {children}
      </div>
    </div>
    ) : null
  }

  return (
    <div className={classes.root}>
      {Boolean(activeModalData) && 
        <Modal 
        shown={modalShown}
        close={() => 
          toggleModal(false)
        }
        >          
          {['Title', 'Caption', 'Exposure', 'Location'].map( field => (
            activeModalData[field.toLowerCase()] && [ field, activeModalData[field.toLowerCase()] ]
          )).filter(Boolean).map( ( [field, value] ) => 
            <>
              <Typography variant="h3">
                {field}
              </Typography>              
              <Typography variant="caption">
                {value}
              </Typography>
            </>
          ) }           
        </Modal>  
      } 
      <Gallery        
        uiElements={uiElements}
        id
        withCaption        
      >
      { data?.images?.map( (image, index ) => {
        
        let caption =  (image.title || image.caption || '')        
        
        if (caption.length > 200) {
          caption = caption.split('').slice(0, 199).join('') + '...(see info for more)'
        } 

        return (
          <Item            
            id={image.id}
            key={image.id}
            thumbnail={`images/galleries/${galleryId}/images/largeThumbs/${image.id}.jpg`} 
            original={`images/galleries/${galleryId}/images/${width < 850 ? 'medium' :'large'}/${image.id}.jpg`}
            width={ image.aspectRatio > 1 ? 1600 : ( 1600 * image.aspectRatio ) }
            height={ image.aspectRatio < 1 ? 1600 : ( 1600 / image.aspectRatio ) }
            caption={ caption }                                                            
          >          
            { ( { ref, open } )  => (                               
                <div>
                  <img
                    ref={ref}
                    // onClick={() => {
                    //   open()
                    //   setCurInfo(image.caption)
                    //   setCurExpo(image.exposure)
                    // }}
                    onClick={open}
                    className={classes.thumbnail}
                    style={{
                      width: `${targetSize}px`,
                      height: `${targetSize}px`,
                      animationDelay: `${parseInt(index*10)}ms`                               
                    }}
                    src={`images/galleries/${galleryId}/images/largeThumbs/${image.id}.jpg`}
                    alt=""
                  />                                              
                </div>                
            ) }                          
          </Item>                    
        ) 
      } ) }
    </Gallery>        
  </div>
  )

}
export default withSize()(PhotoGallery)