import { createMuiTheme } from "@material-ui/core/styles";

// colour theme
// red #f44336 or #e60c2f ??
// grey rgba(0, 0, 0, 0.54) or rgba(0, 0, 0, 0.87) or #eee
// light grey rgba(0, 0, 0, 0.1)
// light blue rgb(31, 140, 193) or #1f8cc1
// dark blue rgba(21,99,135,255) or #156387

const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#333",
    },
    secondary: {
      main: "#eee",
    },
    error: {
      main: "#e60c2f",
    },
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    h3 : {
      fontSize: '1.2rem',
      lineHeight: '1.5rem',
      marginTop: '8px'
    },
  },
  overrides: {
    // MuiButton: {
    //   root: {
    //     textTransform: "none",
    //   },
    // },
    // MuiCard: {
    //   root: {
    //     borderRadius: 8,
    //   },
    // },
    // MuiFab: {
    //   root: {
    //     textTransform: "none",
    //   },
    // },
  },
};

export const Theme = createMuiTheme({
  ...themeOptions,
});

export const FooterTheme = createMuiTheme({
  ...themeOptions,
  palette: {
    type: "dark",
  },
});
