export default {
  "useFlickr": "false",
	"galleryTitle": "JØRGEN RASMUSSEN | PHOTOGRAPHY  Gallary: Site Title",
	"textColor": "rgba(255, 255, 255, 1)",
	"thumbFrameColor": "rgba(255, 255, 255, 0.5)",
	"showOpenButton": "true",
	"showExpandButton": "true",
	"useFullscreenExpand": "true",
	"enableDirectLinks": "true",
	"expandInNewPage": "false",
	"topAreaHeight": "0",
	"galleryFontFace": "'Helvetica Neue', Helvetica, Arial, 'lucida grande', tahoma, verdana, arial, sans-serif;",
	"galleryDescription": "Gallery description",
  "galleryTitlePosition": "NONE",
	"showThumbsButton": "true",
	"captionBackColor": "rgba(0,0,0,0)",
	"maxThumbRows": "1",
	"maxThumbColumns": "5",
	"showThumbsOnLoad": "false",
	"showSmallThumbsOnLoad": "false",
	"showSmallThumbsButton": "false",
	"showPagingText": "false",
	"thumbNavPosition": "CENTER",
	"buttonBarHAlign": "RIGHT",
	"showInfoButton": "false",
	"showNavButtons": "false",
	"buttonBarIconSize": "25",
	"captionPosition": "OVERLAY_IMAGE",
	"maxCaptionHeight": "320",
	"showImageNumber": "true",
	"shareFacebook": "true",
	"shareTwitter": "true",
	"shareGPlus": "false",
	"sharePinterest": "true",
	"shareTumblr": "false",
	"shareUrl": "http://www.jorgen-rasmussen.com/portfolio/",
	"addSEOContent": "false",
	"backButtonPosition": "OVERLAY",
	"backButtonUseIcon": "true",
  "backButtonUrl": "http://www.jorgen-rasmussen.com",
  "showSmallBackButton": "true",
	"changeImageOnHover": "false",
	"navButtonIconSize": "20",
	"showSmallPagingText": "false",
	"navButtonBackColor": "rgba(255,255,255,0)",
	"textShadowColor": "rgba(0,0,0,.5)",
	"buttonBarBackColor": "rgba(0,0,0,0)",
	"imageFrameColor": "rgba(255,255,255,0)",
	"buttonBarIconHoverColor": "rgba(204,204,204,1)",
	"buttonBarIconColor": "rgba(255,255,255,.95)",
	"imageScaleMode": "SCALE",
	"buttonBarPosition": "OVERLAY",
	"backButtonHAlign": "LEFT",
	"useLargeImages": "true",
	"useSmallImages": "true",
  "images": [          {
        "id": "_JR27617",
        "title": ``,
        "caption": `Juvenile Wunderpus (Wunderpus photogenicus)`,
        "exposure":  "1/200 sec at f / 20",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR04818-Edit",
        "title": ``,
        "caption": `Juvenile Wunderpus (Wunderpus photogenicus)`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR05453",
        "title": ``,
        "caption": `Juvenile Wunderpus (Wunderpus photogenicus)`,
        "exposure":  "1/250 sec at f / 29",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR27529",
        "title": ``,
        "caption": `Juvenile Wunderpus (Wunderpus photogenicus)`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR03339",
        "title": ``,
        "caption": `Juvenile Wunderpus (Wunderpus photogenicus)`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC8886",
        "title": ``,
        "caption": `Juvenile Wunderpus (Wunderpus photogenicus)`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04310",
        "title": ``,
        "caption": `Juvenile Wunderpus (Wunderpus photogenicus)`,
        "exposure":  "1/200 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06505",
        "title": ``,
        "caption": `Larval Tonguefish`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05673",
        "title": ``,
        "caption": `Juvenile sharpear enope squid (Ancistrocheirus lesueurii)`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06181",
        "title": ``,
        "caption": `Juvenile sharpear enope squid (Ancistrocheirus lesueurii)`,
        "exposure":  "1/200 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR07986-Edit",
        "title": ``,
        "caption": `Trevally seeking protection in Jellyfish`,
        "exposure":  "1/200 sec at f / 18",
        "location": "",
        "aspectRatio": 1.5238095238095      } ,       
          {
        "id": "_JR26117",
        "title": ``,
        "caption": `Trevally seeking protection in Jellyfish`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC8061-Edit",
        "title": ``,
        "caption": `Trevally seeking protection in Jellyfish`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC7936",
        "title": ``,
        "caption": `Trevally seeking protection in Jellyfish`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR28002",
        "title": ``,
        "caption": `Unidentified Octopus`,
        "exposure":  "1/125 sec at f / 20",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR29799",
        "title": ``,
        "caption": `Larval Flounder (assumed Laeops nigromaculatus )`,
        "exposure":  "1/160 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR28135",
        "title": ``,
        "caption": `Juvenile Many-spotted lefteye flounder (Arnoglossus Polyspilus)`,
        "exposure":  "1/200 sec at f / 20",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC9383",
        "title": ``,
        "caption": `Flounder`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR07108",
        "title": ``,
        "caption": `Three Spotted Flounder`,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR01795",
        "title": ``,
        "caption": `Flounder`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR21119",
        "title": ``,
        "caption": `Female Blanket Octopus (Tremoctopus)`,
        "exposure":  "1/160 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR20942",
        "title": ``,
        "caption": `Female Blanket Octopus (Tremoctopus)`,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC8641",
        "title": ``,
        "caption": `Female Blanket Octopus (Tremoctopus)`,
        "exposure":  "1/200 sec at f / 18",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC9615",
        "title": ``,
        "caption": `Female Blanket Octopus (Tremoctopus)`,
        "exposure":  "1/200 sec at f / 18",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR00543",
        "title": ``,
        "caption": `Male Blanket Octopus (Tremoctopus)`,
        "exposure":  "1/200 sec at f / 36",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR22308",
        "title": ``,
        "caption": `Juvenile Blanket Octopus (Tremoctopus)`,
        "exposure":  "1/160 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC9123",
        "title": ``,
        "caption": `Eel Larva - possible Moray or Ribbon`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR07676",
        "title": ``,
        "caption": `Juvenile conger eel`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR07203",
        "title": ``,
        "caption": `Juvenile conger eel`,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR22469",
        "title": ``,
        "caption": `Tiny Octopus on editable algae`,
        "exposure":  "1/100 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR28150",
        "title": ``,
        "caption": `Male Paper Nautilus hitchhiking on Jellyfish`,
        "exposure":  "1/125 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR28930",
        "title": ``,
        "caption": `Male Paper Nautilus hitchhiking on Jellyfish`,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 0.671875      } ,       
          {
        "id": "_JR28952",
        "title": ``,
        "caption": `Male Paper Nautilus hitchhiking on Jellyfish`,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR05763",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR26885",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR03710",
        "title": ``,
        "caption": `Female Paper Nautilus`,
        "exposure":  "1/250 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04473",
        "title": ``,
        "caption": `Female Paper Nautilus`,
        "exposure":  "1/200 sec at f / 32",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR20477",
        "title": ``,
        "caption": `Female Paper Nautilus`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC7544",
        "title": ``,
        "caption": `Female Paper Nautilus`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR08228",
        "title": ``,
        "caption": `Female Paper Nautilus`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR04127",
        "title": ``,
        "caption": `Female Paper Nautilus`,
        "exposure":  "1/200 sec at f / 18",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR03623",
        "title": ``,
        "caption": `Female Paper Nautilus`,
        "exposure":  "1/250 sec at f / 29",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC7736",
        "title": ``,
        "caption": `Female Paper Nautilus`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR03874",
        "title": ``,
        "caption": `Female Argonaut without shell`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR21872-Edit",
        "title": ``,
        "caption": ``,
        "exposure":  "1/160 sec at f / 20",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR21926-Edit",
        "title": ``,
        "caption": `Juvenile African Pompano (Alectis ciliaris),`,
        "exposure":  "1/160 sec at f / 20",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05767",
        "title": ``,
        "caption": `Juvenile African Pompano (Alectis ciliaris),`,
        "exposure":  "1/200 sec at f / 20",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05811",
        "title": ``,
        "caption": `Juvenile African Pompano (Alectis ciliaris),`,
        "exposure":  "1/200 sec at f / 20",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR28161",
        "title": ``,
        "caption": `Juvenile African Pompano (Alectis ciliaris),`,
        "exposure":  "1/125 sec at f / 18",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR27971",
        "title": ``,
        "caption": `Juvenile Diamond Squid`,
        "exposure":  "1/125 sec at f / 20",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04930",
        "title": ``,
        "caption": `Juvenile Diamond Squid`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR28964",
        "title": ``,
        "caption": `Juvenile Diamond Squid`,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR29155",
        "title": ``,
        "caption": `Juvenile Diamond Squid`,
        "exposure":  "1/160 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR29183",
        "title": ``,
        "caption": `Juvenile Diamond Squid`,
        "exposure":  "1/160 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC7494",
        "title": ``,
        "caption": `Juvenile Puffer Fish`,
        "exposure":  "1/200 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR07748",
        "title": ``,
        "caption": `Juvenile Puffer Fish with Hitchhiking crab`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR07763",
        "title": ``,
        "caption": `Juvenile Puffer Fish with Hitchhiking crab`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06206",
        "title": ``,
        "caption": `Juvenile Puffer Fish with three Isopods on head`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR02723",
        "title": ``,
        "caption": `Long Arm Octopus`,
        "exposure":  "1/250 sec at f / 18",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR26703",
        "title": ``,
        "caption": `Long Arm Octopus`,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR26673",
        "title": ``,
        "caption": `Long Arm Octopus`,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR26696",
        "title": ``,
        "caption": `Long Arm Octopus`,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR26268",
        "title": ``,
        "caption": `Seahorse drifting and feeding on isopods`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR20690",
        "title": ``,
        "caption": `Seahorse drifting with plastic`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR26502",
        "title": ``,
        "caption": `Squid`,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR07189",
        "title": ``,
        "caption": `Squid`,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR06104",
        "title": ``,
        "caption": `Squid eating fish`,
        "exposure":  "1/200 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06330",
        "title": ``,
        "caption": `Juvenile Frog Fish`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06719",
        "title": ``,
        "caption": `Juvenile Frog Fish`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR06337",
        "title": ``,
        "caption": `Juvenile Frog Fish`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 0.671875      } ,       
          {
        "id": "_JR25867",
        "title": ``,
        "caption": `Crab at early Zoea stage. Crabs morph through several stages in their pelagic life before settling in their final form.`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05341",
        "title": ``,
        "caption": `Crab in the final Magalopa pelagic stagge. Crabs morph through several stages in their pelagic life before settling in their final form.`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR05377",
        "title": ``,
        "caption": `Crab in the final Magalopa pelagic stagge. Crabs morph through several stages in their pelagic life before settling in their final form.`,
        "exposure":  "1/250 sec at f / 29",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR05203",
        "title": `Crab eat Crab`,
        "caption": `Crab eat Crab.  Crabs morph through several stages in their pelagic life before settling in their final form.  In this photo, a crab in the final Magalopa stage attacks another crab in the earlier Zoea stage.`,
        "exposure":  "1/200 sec at f / 20",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR05015",
        "title": ``,
        "caption": ``,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05013",
        "title": ``,
        "caption": `Ocpopuses interacting. Possibly mating or fighting`,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05910",
        "title": ``,
        "caption": `Trevally seeking protection in Jellyfish`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR05964",
        "title": ``,
        "caption": `Trevally seeking protection in Jellyfish`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR09563",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC6977",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 29",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR21440",
        "title": ``,
        "caption": ``,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05598",
        "title": ``,
        "caption": `Flying Fish with Amphipod`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR05600",
        "title": ``,
        "caption": `Flying Fish with Amphipod closeup`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR00358",
        "title": ``,
        "caption": `Flying Fish`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR00476",
        "title": ``,
        "caption": `Flying Fish`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR01500",
        "title": ``,
        "caption": `Flying Fish with surface reflection`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR01505",
        "title": ``,
        "caption": `Flying Fish with surface reflection`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.671875      } ,       
          {
        "id": "_JR01550",
        "title": ``,
        "caption": `Flying Fish with surface reflection`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR26531",
        "title": ``,
        "caption": `Barred Soap Fish`,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR28299",
        "title": ``,
        "caption": `Snake blenny (Xiphasia setifer) juvenile with parasite attached`,
        "exposure":  "1/250 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR28317",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 18",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR28325",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 18",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR21491",
        "title": ``,
        "caption": ``,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR20403",
        "title": ``,
        "caption": `Juvenile Flying Gurnard`,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR09419",
        "title": ``,
        "caption": `Juvenile Flying Gurnard`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR21601",
        "title": ``,
        "caption": `Juvenile Flying Gurnard`,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR21012",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05228",
        "title": `Sea Angel (Cliopsis krohnii)`,
        "caption": ``,
        "exposure":  "1/200 sec at f / 20",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR03808",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR21526",
        "title": ``,
        "caption": `Mototi Octopus`,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05990",
        "title": ``,
        "caption": `Mototi Octopus`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR27053",
        "title": ``,
        "caption": `Bobtail Squid`,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04993",
        "title": ``,
        "caption": `Unidentified. Id help appreciated`,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR06924",
        "title": ``,
        "caption": `Pelagic Nudibranch`,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR05606",
        "title": ``,
        "caption": `Pelagic Nudibranch`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR06190",
        "title": ``,
        "caption": `Squid`,
        "exposure":  "1/200 sec at f / 20",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR08410",
        "title": ``,
        "caption": `Juvenile White Lip Trevally`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR29562",
        "title": ``,
        "caption": `Juvenile White Lip Trevally`,
        "exposure":  "1/160 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR09370",
        "title": ``,
        "caption": `Seahorse riding a Ribbon`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR03241",
        "title": `Juvenile Pipefish in pelagic stage`,
        "caption": ``,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR09086",
        "title": ``,
        "caption": `Juvenile Seahorse`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR01870",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR02147",
        "title": ``,
        "caption": `Juvenile Seahorse`,
        "exposure":  "1/250 sec at f / 29",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR09809",
        "title": ``,
        "caption": `Jellyfish with 2 Isopods`,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR01200",
        "title": ``,
        "caption": `Juvenile Sole fish`,
        "exposure":  "1/250 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR23973",
        "title": ``,
        "caption": `Juvenile Sole fish`,
        "exposure":  "1/200 sec at f / 36",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR26378",
        "title": ``,
        "caption": `Juvenile Sole`,
        "exposure":  "1/250 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR26384",
        "title": ``,
        "caption": `Juvenile Sole`,
        "exposure":  "1/250 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR22894",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR07009",
        "title": ``,
        "caption": `Lionfish Larvae`,
        "exposure":  "1/250 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR03273",
        "title": ``,
        "caption": `Lionfish Larvae`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR07348",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR01733",
        "title": ``,
        "caption": `Juvenile Lion or Scorpion fish`,
        "exposure":  "1/200 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06934",
        "title": ``,
        "caption": `Probavly juvenile cookatoo wasp fish`,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR03340",
        "title": ``,
        "caption": `worm family Alciopidae with eggs. The eggs are held together in a gelatinous sac and the worm swims around continuesly probably guarding it and keeping it afloat.`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR03363",
        "title": ``,
        "caption": `worm family Alciopidae with eggs. The eggs are held together in a gelatinous sac and the worm swims around continuesly probably guarding it and keeping it afloat.`,
        "exposure":  "1/160 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04075",
        "title": ``,
        "caption": `unidentified worm`,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR05167",
        "title": ``,
        "caption": `Jellyfish eating fish`,
        "exposure":  "1/250 sec at f / 29",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC9227",
        "title": ``,
        "caption": `larval Crocodile Toothfish, family Champsodontidae`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR02817",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 18",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR24381",
        "title": ``,
        "caption": `Juvenile mantis shrimp`,
        "exposure":  "1/320 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR02935",
        "title": ``,
        "caption": `Juvenile mantis shrimp`,
        "exposure":  "1/250 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR06217",
        "title": ``,
        "caption": `Juvenile mantis shrimp`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR03623_1",
        "title": ``,
        "caption": `Phronimas amphipods look like the aliens Hollywood imagined. 
This females of these deep sea parasitoids attack a host salp and eat the tissue from the inside making a hollow. She then lay eggs inside. Once the eggs hatches, she continue nurturing the young inside and they too feed on the host salp all while they travel the open ocean together.`,
        "exposure":  "1/200 sec at f / 29",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR22552",
        "title": ``,
        "caption": `Phronimas amphipods look like the aliens Hollywood imagined. 
This females of these deep sea parasitoids attack a host salp and eat the tissue from the inside making a hollow. She then lay eggs inside. Once the eggs hatches, she continue nurturing the young inside and they too feed on the host salp all while they travel the open ocean together.`,
        "exposure":  "1/125 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR03879",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR06641",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 22",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR03453",
        "title": ``,
        "caption": `Crab hitchhicking and possibly feeding on Venus Girdle (Cestum veneris)`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR01434",
        "title": ``,
        "caption": `Juvenile Half Beak Fish`,
        "exposure":  "1/200 sec at f / 29",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR05301",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR07532",
        "title": ``,
        "caption": `Juvenile Moorish Idol`,
        "exposure":  "1/200 sec at f / 20",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04228",
        "title": ``,
        "caption": `Blackchin, Neoscopelus species, family Neoscopelidae. It's a relative of the Lanternfishes, family Myctophidae.`,
        "exposure":  "1/200 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04254",
        "title": ``,
        "caption": `Blackchin, Neoscopelus species, family Neoscopelidae. It's a relative of the Lanternfishes, family Myctophidae.`,
        "exposure":  "1/200 sec at f / 29",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR05391",
        "title": `Barbeled dragonfish (Aristostomias sp.)`,
        "caption": ``,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04867",
        "title": ``,
        "caption": `Juvenile Goby`,
        "exposure":  "1/200 sec at f / 32",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR23862",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 36",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR27861",
        "title": ``,
        "caption": `Juvenile Cow fish Fish`,
        "exposure":  "1/200 sec at f / 25",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05496",
        "title": ``,
        "caption": `Juvenile Box Fish`,
        "exposure":  "1/250 sec at f / 29",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR27520",
        "title": ``,
        "caption": `Unidentified shrimp`,
        "exposure":  "1/125 sec at f / 25",
        "location": "",
        "aspectRatio": 0.6640625      }      
      ]}