import React, { useState } from 'react'
import makeStyles from "@material-ui/styles/makeStyles"
import SubmissionDialog from '../components/SubmissionDialog'
import SubmitErrorDialog from '../components/SubmitErrorDialog'
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "../components/TextField";
import { Form, Field } from "react-final-form";
import { Button } from '@material-ui/core'

const useStyles = makeStyles( theme => ({
    formWrapper: {
        margin: theme.spacing(0,2)
    },
}))

const ContactForm = () => {

    const classes = useStyles();    
    // const isPhone = useMediaQuery(theme.breakpoints.down('xs'));
    const [submitted, setSubmitted] = useState(false)
    const [submitError, setSubmitError] = useState()
   
    const onSubmit = (values) => {
        fetch("https://ohi231zlc6.execute-api.ap-southeast-2.amazonaws.com/api/contact/requestCall", { //https://ohi231zlc6.execute-api.ap-southeast-2.amazonaws.com/api/contact/requestCall https://dev.safeday.app/api/contact/requestCall
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...values, website:'jorgenrasmussen.com'}),
            mode: 'cors'
        })
        .then((response) => {
            if(!response.ok) {
                throw Error('Could not send data')
            } else if (response.ok) {
                setSubmitted(true)
            }
        })
        // .then(() => {
        //     setOpen(false);
        // })
        .catch((error) => {
            console.log(error.message)
            setSubmitError(error.message)
        })
    }
    // Field validators:
    const required = value => value ? undefined : 'Required'
    
    const validateEmail = value => {
        const emailRegex = /\S+@\S+\.\S+/;
        return !value?.match(emailRegex) && 'Please enter a valid email'
    }
    
    const validateNumber = value => {
        if(!value) return undefined
        const numberRegex = /^\d+$/
        return !value?.match(numberRegex) && 'Please enter a valid number'
    }

    return (
      <div className={classes.formWrapper}>
        <Form onSubmit={onSubmit} >
          {({ handleSubmit, form, submitting, pristine, values }) =>   
            <form 
                onSubmit={null}
                noValidate
                autoComplete="on"
            >
              <Typography variant="body1" component="div">
                Interested in collaborative work or using my pictures?
              </Typography>
              <br/>
              <Typography variant="h6" component="div">
                Let me know
              </Typography>  
              <Grid container spacing="2">                       
                <Grid item xs="12" md="6">
                  <Field 
                      component={TextField}
                      name="name"
                      validate={required} 
                      required 
                      variant="outlined" 
                      label="Full Name" 
                      type="text" 
                      id="name"
                  />
                </Grid>                           
                <Grid item xs="12" md="6">
                  <Field 
                      component={TextField}
                      name="contact"
                      validate={validateNumber}
                      variant="outlined" 
                      label="Contact Number" 
                      fullWidth
                      id="contact" 
                  />
                </Grid>
                <Grid item xs="12">
                  <Field 
                      component={TextField}
                      name="email"
                      validate={validateEmail}
                      required 
                      variant="outlined" 
                      label="Email" 
                      type="text" 
                      id="email"
                  />
                </Grid>
                <Grid item xs="12">
                                                
                </Grid>
                <Grid item xs="12">
                  <Field 
                      component={TextField}
                      name="message"
                      validate={required}
                      required 
                      variant="outlined" 
                      label="Message"
                      type="text" 
                      id="context"
                      multiline                 
                      rows={3}
                  />
                  <br />
                  <br />
                </Grid>
                {/* <Grid item xs="12">
                  <Field
                    name="recaptcha"
                    validate={required}
                    component={RecaptchaField}
                  />
                </Grid> */}
              </Grid>                      
              <div className={classes.actions}>                         
                  <Button onClick={handleSubmit} color="primary" variant="contained">SUBMIT</Button>
              </div>
            </form>
          }
        </Form>
        {submitted && 
          <SubmissionDialog
            onClose={ ()=>{
              setSubmitted(false)
              window.location="/#"
            } }
          />
        }
        { submitError && 
          <SubmitErrorDialog
            message={submitError}
            onClose={ ()=> setSubmitError() }
          />
        }
      </div>
    )
}

export default ContactForm