import React, {useState, useEffect} from "react";
import Header from "./Header.jsx";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from "clsx";

import galleries from "data/aboutGallery.js";

const useStyles = makeStyles(theme => ({
    root: {
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative"
    },
    transitionBox: {
      whiteSpace: "nowrap"
    },
    scrollContainer: {
      position: "absolute",
      zIndex: 4,
      width: "100%",
      display: "flex",
      justifyContent: "space-between"
    },
    scroll: {
      border: "none",
      background: "none",
      color: "white",
      cursor: "pointer",
    },
    scrollIcon: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px"
      },
      "&:hover" : {
        transform: "scale(1.5)"
      }
    },
    indicatorContainer: {
      position: "absolute",
      zIndex: 4,
      paddingBottom: theme.spacing(1),
      display: "flex",
      alignSelf: "flex-end"
    },
    indicator: {
      padding: theme.spacing(0, 0.75),
      border: "none",
      background: "none",
      cursor: "pointer",
    },
    indicatorIcon: {
      fontSize: "8px",
      color: 'rgba(255,255,255,0.4)',
      padding: '10px',
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0, 0.065),
        fontSize: "6px"
      },
      
    },
    indicatorIconActive : {
      color : 'white',
      fontSize: '20px',
      padding: 0

    }
}))

const SLIDE_DURATION = 3000

const AboutCarousel = () => {
    

    const classes = useStyles();

    // TODO : Insert new images here for about section
    const slides = galleries.flat()

    const [activeIndex, setActiveIndex] = useState(0)

    const updateIndex = (newIndex) => {
        newIndex > slides.length - 1 ? newIndex = 0 : newIndex = activeIndex + 1
        setActiveIndex(newIndex)
    }

    const handleNext = () => {
      activeIndex !== slides.length - 1 ? setActiveIndex(activeIndex + 1) : setActiveIndex(0)
    }
    const handlePrev = () => {
      activeIndex !== 0 ? setActiveIndex(activeIndex - 1) : setActiveIndex(slides.length - 1)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            updateIndex(activeIndex + 1);
        }, SLIDE_DURATION);

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    })
    
    
    return (
        <Box className={classes.root}>
          <div className={classes.scrollContainer}>
            <button className={classes.scroll} onClick={handlePrev}>
              <ArrowBackIosRoundedIcon className={classes.scrollIcon}/>
            </button>
            <button className={classes.scroll} onClick={handleNext}>
              <ArrowForwardIosRoundedIcon className={classes.scrollIcon}/>
            </button>
          </div>
          <Box 
            className={classes.transitionBox} 
            style={{transform: `translateX(-${activeIndex * 100}%)`, transition: "transform 0.5s", transitionTimingFunction: 'ease'}} 
          >
            {/* Edit props for updated images */}
            {slides.map( gallery =>
              <Header 
                image={`images/banners/${gallery.id}.jpg`} 
                titleText={gallery.title} 
                subtitleText={gallery.subTitle} 
                fontColor={gallery.fontColor} 
                // action="View Gallery"
              />
            )} 
          </Box>
          <div className={classes.indicatorContainer}>
              { slides.map( ( _, index ) =>
                <button 
                  className={classes.indicator}                   
                  onClick={() => setActiveIndex(index)}
                >
                  <FiberManualRecordIcon className={ clsx(classes.indicatorIcon, activeIndex === index && classes.indicatorIconActive) } /> 
                </button>
              ) }
          </div> 
        </Box>
    )
}

export default AboutCarousel;