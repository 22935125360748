import React from 'react'
import TextField from '@material-ui/core/TextField';

const TextFieldWrapper = ( {
  input: {name, onChange, onBlur, value} = {}, 
  meta = {},
  validate,
  fullWidth = true,
  helperText,
  ...rest
} ) => {  
  const hasError = (meta.error && meta.touched) || (meta.invalid && meta.submitFailed)

  return (
        <TextField
          {...rest}
          name={name}
          helperText={hasError ? meta.error : helperText}
          error={hasError}
          fullWidth={fullWidth}
          onChange={(value) => onChange(value)}
          onBlur={onBlur}
          value={value}          
        />
	);
};

export default TextFieldWrapper;