import { Img } from 'react-image';
import React from 'react';
// import Fade from "@material-ui/core/Fade";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
 
const DURATION = 1000;
 
const useStyles = makeStyles(theme => ({
  '@keyframes fadeIn': {
    '0%': { opacity:0 },
    '100%': { opacity:1 }
  },
  container: {

    '& img': {
      width: "100%",
      animation: `$fadeIn ${DURATION}ms ${theme.transitions.easing.easeInOut}`,
    }
  }
}))
 

const Image = ({
  src = '',
  loader,
  unloader,
  className,
  ...rest
}) => {
  const classes = useStyles()
  // const initials = username.split('.').map( str => str.substr(0,1)).join('').toUpperCase()
  return (
    <Img
        // {}
        src={src}
        {...rest}
        container={ children =>
          <div className={clsx(classes.container, className)}>
            {children}
          </div>
        }
        // className={imageClass}
        unloader={ unloader } // to be shown when image is fading in
        // loader={ <div {...rest}> loader </div> }
    />
  )
}
export default Image