import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default function SubmissionDialog({onClose}) {
  return (
    <div>
      <Dialog
        onClose={onClose}
        open
      >
        <DialogTitle id="alert-dialog-title">Request Submitted!</DialogTitle>
        <DialogContent>
          <div style={{padding:20, textAlign:'center', color:'green', fontSize:'70px'}}>
            <CheckCircleIcon color="inherit" fontSize="inherit" />
          </div>
          <DialogContentText id="alert-dialog-description">
            Thank you for contacting us. We will reach out to you shortly.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}