export default {
  "useFlickr": "false",
	"galleryTitle": "JØRGEN RASMUSSEN | PHOTOGRAPHY  Gallary: Site Title",
	"textColor": "rgba(255, 255, 255, 1)",
	"thumbFrameColor": "rgba(255, 255, 255, 0.5)",
	"showOpenButton": "true",
	"showExpandButton": "true",
	"useFullscreenExpand": "true",
	"enableDirectLinks": "true",
	"expandInNewPage": "false",
	"topAreaHeight": "0",
	"galleryFontFace": "'Helvetica Neue', Helvetica, Arial, 'lucida grande', tahoma, verdana, arial, sans-serif;",
	"galleryDescription": "Gallery description",
  "galleryTitlePosition": "NONE",
	"showThumbsButton": "true",
	"captionBackColor": "rgba(0,0,0,0)",
	"maxThumbRows": "1",
	"maxThumbColumns": "5",
	"showThumbsOnLoad": "false",
	"showSmallThumbsOnLoad": "false",
	"showSmallThumbsButton": "false",
	"showPagingText": "false",
	"thumbNavPosition": "CENTER",
	"buttonBarHAlign": "RIGHT",
	"showInfoButton": "false",
	"showNavButtons": "false",
	"buttonBarIconSize": "25",
	"captionPosition": "OVERLAY_IMAGE",
	"maxCaptionHeight": "320",
	"showImageNumber": "true",
	"shareFacebook": "true",
	"shareTwitter": "true",
	"shareGPlus": "false",
	"sharePinterest": "true",
	"shareTumblr": "false",
	"shareUrl": "http://www.jorgen-rasmussen.com/portfolio/",
	"addSEOContent": "false",
	"backButtonPosition": "OVERLAY",
	"backButtonUseIcon": "true",
  "backButtonUrl": "http://www.jorgen-rasmussen.com",
  "showSmallBackButton": "true",
	"changeImageOnHover": "false",
	"navButtonIconSize": "20",
	"showSmallPagingText": "false",
	"navButtonBackColor": "rgba(255,255,255,0)",
	"textShadowColor": "rgba(0,0,0,.5)",
	"buttonBarBackColor": "rgba(0,0,0,0)",
	"imageFrameColor": "rgba(255,255,255,0)",
	"buttonBarIconHoverColor": "rgba(204,204,204,1)",
	"buttonBarIconColor": "rgba(255,255,255,.95)",
	"imageScaleMode": "SCALE",
	"buttonBarPosition": "OVERLAY",
	"backButtonHAlign": "LEFT",
	"useLargeImages": "true",
	"useSmallImages": "true",
  "images": [          {
        "id": "JorgenRasmussenCP01",
        "title": ``,
        "caption": `Enormous jaws and equally impressive teeths allows leopard seals to prey on the smallest krill as well as other seals and pinguins. These intellengent and agile animals are top predators perfectly adapted to the icy antarctic waters.`,
        "exposure":  "1/400 sec at f / 13",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR12512-Edit",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR12752-Edit",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR13315",
        "title": ``,
        "caption": ``,
        "exposure":  "1/320 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR13160-Edit",
        "title": ``,
        "caption": ``,
        "exposure":  "1/320 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR18882-Edit",
        "title": ``,
        "caption": ``,
        "exposure":  "1/320 sec at f / 4.5",
        "location": "Antarctica",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_ALH9160",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 20",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_ALH9130",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 16",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR13413",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05301",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05345",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "DSC_0793",
        "title": ``,
        "caption": `Australian sea lions chilling`,
        "exposure":  "1/2000 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_AAA7354",
        "title": ``,
        "caption": `Australian sea lion pups - do you wanna play?`,
        "exposure":  "1/250 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR14696",
        "title": ``,
        "caption": `Australian Sea lion`,
        "exposure":  "1/250 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR14759",
        "title": ``,
        "caption": `Australian Sea lion`,
        "exposure":  "1/250 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR14774",
        "title": ``,
        "caption": `Australian Sea lion`,
        "exposure":  "1/250 sec at f / 10",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR15130",
        "title": ``,
        "caption": `Australian Sea lion`,
        "exposure":  "1/250 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR07948",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR07961",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR08001",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR08211",
        "title": ``,
        "caption": ``,
        "exposure":  "1/320 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR08254",
        "title": ``,
        "caption": ``,
        "exposure":  "1/320 sec at f / 5.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR08257",
        "title": ``,
        "caption": ``,
        "exposure":  "1/320 sec at f / 5.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR08279",
        "title": ``,
        "caption": ``,
        "exposure":  "1/320 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR08416",
        "title": ``,
        "caption": ``,
        "exposure":  "1/320 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR08543",
        "title": ``,
        "caption": ``,
        "exposure":  "1/320 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR08888",
        "title": ``,
        "caption": ``,
        "exposure":  "1/320 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR09041",
        "title": ``,
        "caption": ``,
        "exposure":  "1/320 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR04736",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 6.3",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05249",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR05372",
        "title": ``,
        "caption": ``,
        "exposure":  "1/500 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jrasmussen_aaa6637",
        "title": ``,
        "caption": `Fur seal and photographer`,
        "exposure":  "1/250 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      }      
      ]}