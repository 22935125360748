import React, {useState, createContext, useRef} from "react";
import {
  Route,
  Switch,
  HashRouter as Router, // use Hashrouter to allow SPA and load the index page regardless of deployment
} from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Theme } from "./theme";

import Container from "@material-ui/core/Container";

import TopBar from "components/TopBar";
import SiteMapFooter from "components/FooterSiteMap";
import HomePage from "pages/HomePage";
import GalleriesPage from "pages/GalleriesPage";
import AboutPage from "pages/AboutPage";
import ContactPage from "pages/ContactPage";
export const DemoContext = createContext('');

function App() {
  const [open, setOpen] = useState(false);
  const containerRef = useRef()
  return (
    
    <DemoContext.Provider value={{open: open, setOpen: setOpen}}>
      <MuiThemeProvider theme={Theme}>
        <Container 
          // maxWidth="lg" 
          // style={{padding: "0", backgroundColor: "white"}}
          // ref={containerRef}
          disableGutters
        >
          <Router basename="/">
            <TopBar containerRef={containerRef} />
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/galleries/:galleryId" component={GalleriesPage} />
              <Route path="/galleries" exact component={GalleriesPage} />
              <Route path="/contact" exact component={ContactPage} />
              <Route path="/about" component={AboutPage} />                         
            </Switch>
          </Router>
          
          {/* <DialogRequestDemo /> */}
          <SiteMapFooter />
        </Container>
      </MuiThemeProvider>
    </DemoContext.Provider>
  );
}

export default App;
