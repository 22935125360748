export default {
  "useFlickr": "false",
	"galleryTitle": "JØRGEN RASMUSSEN | PHOTOGRAPHY  Gallary: Site Title",
	"textColor": "rgba(255, 255, 255, 1)",
	"thumbFrameColor": "rgba(255, 255, 255, 0.5)",
	"showOpenButton": "true",
	"showExpandButton": "true",
	"useFullscreenExpand": "true",
	"enableDirectLinks": "true",
	"expandInNewPage": "false",
	"topAreaHeight": "0",
	"galleryFontFace": "'Helvetica Neue', Helvetica, Arial, 'lucida grande', tahoma, verdana, arial, sans-serif;",
	"galleryDescription": "Gallery description",
  "galleryTitlePosition": "NONE",
	"showThumbsButton": "true",
	"captionBackColor": "rgba(0,0,0,0)",
	"maxThumbRows": "1",
	"maxThumbColumns": "5",
	"showThumbsOnLoad": "false",
	"showSmallThumbsOnLoad": "false",
	"showSmallThumbsButton": "false",
	"showPagingText": "false",
	"thumbNavPosition": "CENTER",
	"buttonBarHAlign": "RIGHT",
	"showInfoButton": "false",
	"showNavButtons": "false",
	"buttonBarIconSize": "25",
	"captionPosition": "OVERLAY_IMAGE",
	"maxCaptionHeight": "320",
	"showImageNumber": "true",
	"shareFacebook": "true",
	"shareTwitter": "true",
	"shareGPlus": "false",
	"sharePinterest": "true",
	"shareTumblr": "false",
	"shareUrl": "http://www.jorgen-rasmussen.com/portfolio/",
	"addSEOContent": "false",
	"backButtonPosition": "OVERLAY",
	"backButtonUseIcon": "true",
  "backButtonUrl": "http://www.jorgen-rasmussen.com",
  "showSmallBackButton": "true",
	"changeImageOnHover": "false",
	"navButtonIconSize": "20",
	"showSmallPagingText": "false",
	"navButtonBackColor": "rgba(255,255,255,0)",
	"textShadowColor": "rgba(0,0,0,.5)",
	"buttonBarBackColor": "rgba(0,0,0,0)",
	"imageFrameColor": "rgba(255,255,255,0)",
	"buttonBarIconHoverColor": "rgba(204,204,204,1)",
	"buttonBarIconColor": "rgba(255,255,255,.95)",
	"imageScaleMode": "SCALE",
	"buttonBarPosition": "OVERLAY",
	"backButtonHAlign": "LEFT",
	"useLargeImages": "true",
	"useSmallImages": "true",
  "images": [          {
        "id": "_DSC6139",
        "title": ``,
        "caption": `Great White Shark (carcharodon carcharias)
This is about as big as they get! This majestic female is estimated to be around 15-16ft and weight between 1.5 and 2 tonnes.`,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC6318",
        "title": ``,
        "caption": `Great White Shark (carcharodon carcharias)`,
        "exposure":  "1/250 sec at f / 8.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC6325",
        "title": ``,
        "caption": `Great White Shark (carcharodon carcharias)`,
        "exposure":  "1/250 sec at f / 8.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR00282",
        "title": ``,
        "caption": `bull shark (Carcharhinus leucas)`,
        "exposure":  "1/320 sec at f / 14",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR02340",
        "title": ``,
        "caption": `bull shark (Carcharhinus leucas)`,
        "exposure":  "1/250 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR00656",
        "title": ``,
        "caption": `bull shark (Carcharhinus leucas)`,
        "exposure":  "1/320 sec at f / 13",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR10217",
        "title": ``,
        "caption": `Lemon shark (Negaprion brevirostris)`,
        "exposure":  "1/250 sec at f / 13",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC8446",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/100 sec at f / 8.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC6944",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/125 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC7047",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/100 sec at f / 7.1",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC9141",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/100 sec at f / 5.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "PT_283330_whaleshark_in_the_rays_cenderawasih",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/100 sec at f / 5.6",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC7650",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/100 sec at f / 16",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-14707",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/200 sec at f / 9.0",
        "location": "Indonesia",
        "aspectRatio": 0.671875      } ,       
          {
        "id": "jorgen-rasmussen-14624",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-15057",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/200 sec at f / 6.3",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-15234",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/250 sec at f / 10",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-15985",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/250 sec at f / 11",
        "location": "Indonesia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-17102",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/250 sec at f / 6.3",
        "location": "Indonesia",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR09350",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/320 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR09365",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/320 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR09446",
        "title": ``,
        "caption": `Whaleshark (Rhincodon typus)`,
        "exposure":  "1/320 sec at f / 9.0",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR00370",
        "title": ``,
        "caption": `Tiger shark (Galeocerdo cuvier)`,
        "exposure":  "1/320 sec at f / 14",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR00162",
        "title": ``,
        "caption": `Tiger shark (Galeocerdo cuvier)`,
        "exposure":  "1/320 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR00298",
        "title": ``,
        "caption": `Tiger shark (Galeocerdo cuvier)`,
        "exposure":  "1/320 sec at f / 14",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR00413",
        "title": ``,
        "caption": `Tiger shark (Galeocerdo cuvier)`,
        "exposure":  "1/320 sec at f / 14",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR00615",
        "title": ``,
        "caption": `Tiger shark (Galeocerdo cuvier)`,
        "exposure":  "1/320 sec at f / 13",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR00736",
        "title": ``,
        "caption": `Tiger shark (Galeocerdo cuvier)`,
        "exposure":  "1/320 sec at f / 14",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR00815",
        "title": ``,
        "caption": `Tiger shark (Galeocerdo cuvier)`,
        "exposure":  "1/320 sec at f / 16",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR01056",
        "title": ``,
        "caption": `Tiger shark (Galeocerdo cuvier)`,
        "exposure":  "1/320 sec at f / 20",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR01472",
        "title": ``,
        "caption": `Great Hammerhead Shark (Sphyrna mokarran)`,
        "exposure":  "1/320 sec at f / 8.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR02006",
        "title": ``,
        "caption": `Great Hammerhead Shark (Sphyrna mokarran)`,
        "exposure":  "1/320 sec at f / 18",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR01621",
        "title": ``,
        "caption": `Great Hammerhead Shark (Sphyrna mokarran)`,
        "exposure":  "1/320 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR01694",
        "title": ``,
        "caption": `Great Hammerhead Shark (Sphyrna mokarran)`,
        "exposure":  "1/320 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR01791",
        "title": ``,
        "caption": `Great Hammerhead Shark (Sphyrna mokarran)`,
        "exposure":  "1/320 sec at f / 13",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR01924",
        "title": ``,
        "caption": `Great Hammerhead Shark (Sphyrna mokarran)`,
        "exposure":  "1/320 sec at f / 10",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR01990",
        "title": ``,
        "caption": `Great Hammerhead Shark (Sphyrna mokarran)`,
        "exposure":  "1/320 sec at f / 18",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR02114",
        "title": ``,
        "caption": `Great Hammerhead Shark (Sphyrna mokarran)`,
        "exposure":  "1/320 sec at f / 10",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR02125",
        "title": ``,
        "caption": `Great Hammerhead Shark (Sphyrna mokarran)`,
        "exposure":  "1/320 sec at f / 10",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR02127",
        "title": ``,
        "caption": `Great Hammerhead Shark (Sphyrna mokarran)`,
        "exposure":  "1/320 sec at f / 10",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR02131",
        "title": ``,
        "caption": `Great Hammerhead Shark (Sphyrna mokarran)`,
        "exposure":  "1/320 sec at f / 14",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR02275",
        "title": ``,
        "caption": `Great Hammerhead Shark (Sphyrna mokarran)`,
        "exposure":  "1/250 sec at f / 16",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR02036",
        "title": ``,
        "caption": `Nurse shark (Ginglymostoma cirratum)`,
        "exposure":  "1/320 sec at f / 18",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR02139",
        "title": ``,
        "caption": `Nurse shark (Ginglymostoma cirratum)`,
        "exposure":  "1/320 sec at f / 13",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR01766",
        "title": ``,
        "caption": `Nurse shark (Ginglymostoma cirratum)`,
        "exposure":  "1/320 sec at f / 14",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR02517",
        "title": ``,
        "caption": `Nurse shark (Ginglymostoma cirratum)`,
        "exposure":  "1/200 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR02563",
        "title": ``,
        "caption": ``,
        "exposure":  "1/200 sec at f / 13",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "WU_JORGEN_RASMUSSEN_3",
        "title": ``,
        "caption": `Shark feeding in bait ball, B/W. Winner: 1st Prize, Antibes, Underwater Reporter`,
        "exposure":  "1/250 sec at f / 9.5",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR12671",
        "title": ``,
        "caption": `Lemon shark (Negaprion brevirostris)`,
        "exposure":  "1/160 sec at f / 9.0",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR00142",
        "title": ``,
        "caption": `Lemon shark (Negaprion brevirostris)`,
        "exposure":  "1/320 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR00147",
        "title": ``,
        "caption": `Caribbean reef shark (Carcharhinus perezi)`,
        "exposure":  "1/320 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR10495",
        "title": ``,
        "caption": `Lemon shark (Negaprion brevirostris)`,
        "exposure":  "1/125 sec at f / 22",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen_rasmussen_aaa2151",
        "title": ``,
        "caption": `Leopard Shark`,
        "exposure":  "1/125 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen_rasmussen_aaa2015",
        "title": ``,
        "caption": `Leopard Shark`,
        "exposure":  "1/250 sec at f / 13",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen_rasmussen_JBR2147",
        "title": ``,
        "caption": `Sand tiger shark or Grey Nurse (Carcharias taurus)`,
        "exposure":  "1/90 sec at f / 5.6",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jrasmussen_aaa0149",
        "title": ``,
        "caption": `Sand tiger shark or Grey Nurse (Carcharias taurus)`,
        "exposure":  "1/180 sec at f / 11",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16406",
        "title": ``,
        "caption": `Wobbegong`,
        "exposure":  "1/250 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16422",
        "title": ``,
        "caption": `Wobbegong`,
        "exposure":  "1/250 sec at f / 7.1",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR16705",
        "title": ``,
        "caption": `Wobbegong`,
        "exposure":  "1/200 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR01396",
        "title": ``,
        "caption": `Caribbean reef shark (Carcharhinus perezi)`,
        "exposure":  "1/320 sec at f / 13",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR10771",
        "title": ``,
        "caption": `Caribbean reef shark (Carcharhinus perezi)`,
        "exposure":  "1/125 sec at f / 8.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR10814",
        "title": ``,
        "caption": `Caribbean reef shark (Carcharhinus perezi)`,
        "exposure":  "1/125 sec at f / 8.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "IMG_6986",
        "title": ``,
        "caption": `Photo taken by Sott Portelli. Jorgen photographing tigershark.`,
        "exposure":  "1/125 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR12647",
        "title": ``,
        "caption": `Caribbean reef shark (Carcharhinus perezi)`,
        "exposure":  "1/160 sec at f / 10",
        "location": "",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR12715",
        "title": ``,
        "caption": `Caribbean reef shark (Carcharhinus perezi)`,
        "exposure":  "1/125 sec at f / 9.0",
        "location": "",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR12816",
        "title": ``,
        "caption": `Caribbean reef shark (Carcharhinus perezi)`,
        "exposure":  "1/100 sec at f / 8.0",
        "location": "",
        "aspectRatio": 1.5058823529412      }      
      ]}