export default {
  "useFlickr": "false",
	"galleryTitle": "JØRGEN RASMUSSEN | PHOTOGRAPHY  Gallary: Site Title",
	"textColor": "rgba(255, 255, 255, 1)",
	"thumbFrameColor": "rgba(255, 255, 255, 0.5)",
	"showOpenButton": "true",
	"showExpandButton": "true",
	"useFullscreenExpand": "true",
	"enableDirectLinks": "true",
	"expandInNewPage": "false",
	"topAreaHeight": "0",
	"galleryFontFace": "'Helvetica Neue', Helvetica, Arial, 'lucida grande', tahoma, verdana, arial, sans-serif;",
	"galleryDescription": "Gallery description",
  "galleryTitlePosition": "NONE",
	"showThumbsButton": "true",
	"captionBackColor": "rgba(0,0,0,0)",
	"maxThumbRows": "1",
	"maxThumbColumns": "5",
	"showThumbsOnLoad": "false",
	"showSmallThumbsOnLoad": "false",
	"showSmallThumbsButton": "false",
	"showPagingText": "false",
	"thumbNavPosition": "CENTER",
	"buttonBarHAlign": "RIGHT",
	"showInfoButton": "false",
	"showNavButtons": "false",
	"buttonBarIconSize": "25",
	"captionPosition": "OVERLAY_IMAGE",
	"maxCaptionHeight": "320",
	"showImageNumber": "true",
	"shareFacebook": "true",
	"shareTwitter": "true",
	"shareGPlus": "false",
	"sharePinterest": "true",
	"shareTumblr": "false",
	"shareUrl": "http://www.jorgen-rasmussen.com/portfolio/",
	"addSEOContent": "false",
	"backButtonPosition": "OVERLAY",
	"backButtonUseIcon": "true",
  "backButtonUrl": "http://www.jorgen-rasmussen.com",
  "showSmallBackButton": "true",
	"changeImageOnHover": "false",
	"navButtonIconSize": "20",
	"showSmallPagingText": "false",
	"navButtonBackColor": "rgba(255,255,255,0)",
	"textShadowColor": "rgba(0,0,0,.5)",
	"buttonBarBackColor": "rgba(0,0,0,0)",
	"imageFrameColor": "rgba(255,255,255,0)",
	"buttonBarIconHoverColor": "rgba(204,204,204,1)",
	"buttonBarIconColor": "rgba(255,255,255,.95)",
	"imageScaleMode": "SCALE",
	"buttonBarPosition": "OVERLAY",
	"backButtonHAlign": "LEFT",
	"useLargeImages": "true",
	"useSmallImages": "true",
  "images": [          {
        "id": "jorgen-rasmussen-12525",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 5.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-12697",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 5.0",
        "location": "Denmark",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-13009",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 4.5",
        "location": "Denmark",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-13022",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 4.5",
        "location": "Australia",
        "aspectRatio": 2      } ,       
          {
        "id": "jorgen-rasmussen-13024",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 4.5",
        "location": "Denmark",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-13116",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 5.0",
        "location": "Australia",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "jorgen-rasmussen-13196",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 5.0",
        "location": "Denmark",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3904",
        "title": `Sugar Canes`,
        "caption": `Sugar canes and water`,
        "exposure":  "1/1600 sec at f / 4.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3939",
        "title": `Sugar Cane Patterns`,
        "caption": `Suger canes grows tall and thick. Farmers create path ways for equipment and small railway tracks creating beatiful patterns`,
        "exposure":  "1/1600 sec at f / 4.0",
        "location": "Australia",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC4037",
        "title": `Red tracks`,
        "caption": `Mining is part of Australia's modern landscape. Environmental impacts are widely discussed but reality is that the modern world requires resources and metals making mining a necessecity. We need to ensure the impact is managed. Looking at mining operating from a photographic perspective, they offer interesting patterns and colors. This is a tailing pond.`,
        "exposure":  "1/1600 sec at f / 7.1",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC4112",
        "title": `Red Mud`,
        "caption": `Mining is part of Australia's modern landscape. Environmental impacts are widely discussed but reality is that the modern world requires resources and metals making mining a necessecity. We need to ensure the impact is managed. Looking at mining operating from a photographic perspective, they offer interesting patterns and colors. This is a tailing pond.`,
        "exposure":  "1/2000 sec at f / 7.1",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC4318",
        "title": `Flood Plain Reflections`,
        "caption": `Strong tidal flows in the northern part of Australia creates amazing deltas and flood plains`,
        "exposure":  "1/2500 sec at f / 9.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC4503",
        "title": `Yellow Waters`,
        "caption": `Kakadu witnesses an explosion of life every year when the wetlands floods. Large number of birds breeds in the area and a great plance for spotting Crocodiles up close.`,
        "exposure":  "1/640 sec at f / 6.3",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC4671",
        "title": `Heart Reef`,
        "caption": `Shallow Reefs are extraordinary but at the southern end is a reef that are a little different - in the middle of a shallow pool is this reef shaped like a perfect heart`,
        "exposure":  "1/1600 sec at f / 6.3",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC4741",
        "title": `Reef Patterns`,
        "caption": `When seen from the air, Hardy Reef on the Great Barrrier Reef is like an amazing maze of blue and green`,
        "exposure":  "1/1600 sec at f / 6.3",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC8674",
        "title": `Snake through flood plains`,
        "caption": `Each year, large parts of northern Australia floods and starts an amazing cycle of life. The Adelaide Rivers snakes its way through the wetlands.`,
        "exposure":  "1/1600 sec at f / 4.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16608",
        "title": ``,
        "caption": ``,
        "exposure":  "1/1600 sec at f / 3.5",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16612",
        "title": ``,
        "caption": ``,
        "exposure":  "1/1600 sec at f / 3.5",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC5864",
        "title": `Life around the creeks`,
        "caption": `The Gulf of Carpenteria north of Australia experiences extreme tides creating tidal salt flats for more than 100km Mangroves along creeks create amazing patterns.`,
        "exposure":  "1/2000 sec at f / 2.8",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "1323",
        "title": `November Charlie Romeo`,
        "caption": `Our trusted 206 for our 30+ arerial flights over remote Australia`,
        "exposure":  "1/250 sec at f / 16",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "1609",
        "title": `King George Falls`,
        "caption": `The Kimberly Region of Australia is dotted with spectacular waterfalls.`,
        "exposure":  "1/2000 sec at f / 4.5",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC1790",
        "title": `Milky waters`,
        "caption": `Coastal waters arond the Australias North West coast looks milky due to run-offs from rivers and flood plains`,
        "exposure":  "1/2000 sec at f / 5.6",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC1908",
        "title": `Green Rivers`,
        "caption": `Settlements in the waters makes the rivers look milky and green`,
        "exposure":  "1/2000 sec at f / 5.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC1958",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 5.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC2268",
        "title": `Tidal Rivers of WA`,
        "caption": `The West Coast of WA is dotted with tidal rivers and creeks creating surreal patterns and colors due to washoff from the rivers and flats`,
        "exposure":  "1/2000 sec at f / 5.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC2807",
        "title": `Red waters`,
        "caption": `Edges of Salt flats can offer interesting color combinations of red and almost black waters`,
        "exposure":  "1/2000 sec at f / 4.5",
        "location": "Australia",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC3015",
        "title": `Tidal Bay`,
        "caption": `Just north of Coral Bay in WA is a tidal area rich of minerals creating sureal colors and patterns.`,
        "exposure":  "1/2000 sec at f / 5.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3020",
        "title": `Tital Bay`,
        "caption": `Just north of Coral Bay in WA is a tidal area rich of minerals creating sureal colors and patterns.`,
        "exposure":  "1/2000 sec at f / 5.0",
        "location": "Australia",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC3045",
        "title": `Zooming across Ningaloo`,
        "caption": `A favorie past time of people living in WA is fishing. This little fishing boat is zooming  caroos a shallow area near Nignaloo Reef`,
        "exposure":  "1/2000 sec at f / 4.5",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3139",
        "title": `River in Salt Lake`,
        "caption": `Salt likes near the coast offers amazing patterns and colors when tidal flows are combined with colored settlements.`,
        "exposure":  "1/2000 sec at f / 4.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3195",
        "title": `River in Salt Lake`,
        "caption": `Salt likes near the coast offers amazing patterns and colors when tidal flows are combined with colored settlements.`,
        "exposure":  "1/2000 sec at f / 4.0",
        "location": "Australia",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC3205",
        "title": `River in Salt Lake`,
        "caption": `Salt likes near the coast offers amazing patterns and colors when tidal flows are combined with colored settlements.`,
        "exposure":  "1/2000 sec at f / 4.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3218",
        "title": `Green and blue`,
        "caption": `Stagnating water can create interesting colors and patterns. This picture is taken over a salt lake in the north western part of Australia`,
        "exposure":  "1/2000 sec at f / 4.0",
        "location": "Australia",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC3231",
        "title": `Salt with Water`,
        "caption": `Salt mines are set up in the large salt panes of western Austrlia. Channels are made to lead water to ponds.`,
        "exposure":  "1/2000 sec at f / 4.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3256",
        "title": `Dry Salt`,
        "caption": `The beds of the salt panes creates sureal patterns that looks almost 3D when it cracks. Combined with the pools of the salt mines, it creates an amazing combination of geometry and textures.`,
        "exposure":  "1/2000 sec at f / 4.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3386",
        "title": `Piles of Salt`,
        "caption": `Off Australias most western point is a sail mine. Before shipping the salt, it is piled up on a small island creating an interesting visual`,
        "exposure":  "1/2500 sec at f / 8.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3424",
        "title": `Salt details`,
        "caption": `Drying salt pools creates different hues of green and blue constasted by the red dirt of Australia`,
        "exposure":  "1/2500 sec at f / 8.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3459",
        "title": `Salt Mine`,
        "caption": `Interesting patterns and colors from one of Australias salt mines`,
        "exposure":  "1/2500 sec at f / 8.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3465",
        "title": `Salt patterns`,
        "caption": `Water Channels and Salt pools creates interesting geometry and colors`,
        "exposure":  "1/2500 sec at f / 8.0",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3498",
        "title": `Sand Flows`,
        "caption": `Tidal Flows in miniral rich sand creates interesting abstracts`,
        "exposure":  "1/2500 sec at f / 8.0",
        "location": "Australia",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_DSC3653",
        "title": `Feeding the Lagoon`,
        "caption": `The channges feeding saltwater lagoons creates interesting flows and patterns`,
        "exposure":  "1/2500 sec at f / 6.3",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3763",
        "title": `Lagoons of Shark Bay`,
        "caption": `North of Shark Bay, you will find an amazing area of inlets and blue lagoons that naturally floods at high tides.`,
        "exposure":  "1/2500 sec at f / 6.3",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3781",
        "title": `Feeding the Lagoon`,
        "caption": `The channges feeding saltwater lagoons creates interesting flows and patterns`,
        "exposure":  "1/2500 sec at f / 6.3",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC3824",
        "title": `Blue Lagoon`,
        "caption": `Amazing blue lagoon north of Shark Bay sorounded by red dirt.`,
        "exposure":  "1/2500 sec at f / 6.3",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC4069",
        "title": `Desert Life`,
        "caption": `We often see desert as lifeless. This picture is taken over the massive deserts west of Austrlias centre and reveals an almost life-like myriad of veins with bushes and trees.`,
        "exposure":  "1/2000 sec at f / 4.5",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC4114",
        "title": `Life in the Desert`,
        "caption": `The desrts of Australia is far from life less. Combined with the strikiing red/purple/orange colors of the soil, trees like valleys create an interesting environment`,
        "exposure":  "1/2000 sec at f / 4.5",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC4888",
        "title": `Salt`,
        "caption": `Australias red centre is dotted with Salt lakes that occationally floods. The edges of the lakes can have amazing contrast and patterns`,
        "exposure":  "1/2000 sec at f / 5.6",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC4913",
        "title": `Pink Lake`,
        "caption": `East of Uluru is an area of salt lakes. Flying over it, one pink lake stood out. The patterns were amazing.`,
        "exposure":  "1/2000 sec at f / 5.6",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC5231",
        "title": `Dunes South of Coopers Creek`,
        "caption": `Coopers Creek in Queensland occationally flood. It then creates channels in the flat environment revealing the red sand in Austrlia's interior`,
        "exposure":  "1/640 sec at f / 3.2",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_DSC5304",
        "title": `Life south of Coopers Creek`,
        "caption": `Sand Dunes and occational floods creates interesting patterns and colors in an otherwise bare environment.`,
        "exposure":  "1/1000 sec at f / 2.8",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "jorgen-rasmussen-12193",
        "title": ``,
        "caption": ``,
        "exposure":  "1/1000 sec at f / 8.0",
        "location": "Denmark",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "alhay-aguirre-avila-0641",
        "title": ``,
        "caption": ``,
        "exposure":  "1/250 sec at f / 22",
        "location": "Australia",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR15748",
        "title": ``,
        "caption": ``,
        "exposure":  "1/1250 sec at f / 5.6",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR15807",
        "title": ``,
        "caption": ``,
        "exposure":  "1/1250 sec at f / 10",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR15868",
        "title": ` Skeiðarárjökull`,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 7.1",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR15927",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 7.1",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR15937",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 7.1",
        "location": "Iceland",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR16013",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 7.1",
        "location": "Iceland",
        "aspectRatio": 1.7777777777778      } ,       
          {
        "id": "_JR16130",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 8.0",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16160",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 8.0",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16167",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 8.0",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16182",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 8.0",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16205",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 5.6",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16208",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2000 sec at f / 5.6",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16262",
        "title": ``,
        "caption": ``,
        "exposure":  "1/1600 sec at f / 5.6",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16276",
        "title": `Landmannalaugar`,
        "caption": `Spectacular colors of the Landmannalaugar areas in Icelland`,
        "exposure":  "1/1600 sec at f / 5.6",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16317",
        "title": `Landmannalaugar View`,
        "caption": `Spectacular colors of the Landmannalaugar areas in Icelland`,
        "exposure":  "1/1600 sec at f / 5.6",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16402",
        "title": ``,
        "caption": ``,
        "exposure":  "1/1600 sec at f / 5.0",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16411",
        "title": ``,
        "caption": ``,
        "exposure":  "1/1600 sec at f / 5.0",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16465",
        "title": `Þörsmórk`,
        "caption": ``,
        "exposure":  "1/1600 sec at f / 4.5",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16493",
        "title": `Þörsmórk`,
        "caption": ``,
        "exposure":  "1/1600 sec at f / 4.5",
        "location": "Iceland",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR16501",
        "title": `Þörsmórk`,
        "caption": ``,
        "exposure":  "1/1600 sec at f / 4.5",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16546",
        "title": `Þörsmórk`,
        "caption": `Deep blue lake in volcanic ash`,
        "exposure":  "1/1600 sec at f / 5.0",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR16572",
        "title": ``,
        "caption": ``,
        "exposure":  "1/1600 sec at f / 5.0",
        "location": "Iceland",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR16680",
        "title": ``,
        "caption": ``,
        "exposure":  "1/1600 sec at f / 5.0",
        "location": "Iceland",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR16713",
        "title": ` Skeiðarárjökull`,
        "caption": `Volcanic ash in glacier cracs`,
        "exposure":  "1/1600 sec at f / 5.0",
        "location": "Iceland",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR16785",
        "title": ``,
        "caption": ``,
        "exposure":  "1/1250 sec at f / 5.0",
        "location": "Iceland",
        "aspectRatio": 1.5058823529412      } ,       
          {
        "id": "_JR17128",
        "title": `Warburton River inflow to Lake Eyre`,
        "caption": ``,
        "exposure":  "1/2500 sec at f / 6.3",
        "location": "Australia",
        "aspectRatio": 2      } ,       
          {
        "id": "_JR17136",
        "title": `Warburton River inflow to Lake Eyre`,
        "caption": ``,
        "exposure":  "1/2500 sec at f / 6.3",
        "location": "Australia",
        "aspectRatio": 2      } ,       
          {
        "id": "_JR17177",
        "title": `Warburton River inflow to Lake Eyre`,
        "caption": ``,
        "exposure":  "1/2500 sec at f / 6.3",
        "location": "Australia",
        "aspectRatio": 0.6640625      } ,       
          {
        "id": "_JR17747",
        "title": ``,
        "caption": ``,
        "exposure":  "1/3200 sec at f / 5.0",
        "location": "Australia",
        "aspectRatio": 2      } ,       
          {
        "id": "_JR17762",
        "title": ``,
        "caption": ``,
        "exposure":  "1/2500 sec at f / 4.5",
        "location": "Australia",
        "aspectRatio": 0.6640625      }      
      ]}